
const Upload = ({ width = 80, height = 80, primary= '#5468FA', primary_light= '#CCD2FD', primary_dark= '#495AD9' }: { width?: number, height?: number, primary?: string, primary_light?: string, primary_dark?: string }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9346 12.763V63.4778C14.9346 63.8574 15.2424 64.1652 15.6221 64.1652H61.5232C61.9029 64.1652 62.2107 63.8574 62.2107 63.4778V17.0607C62.2107 16.6811 61.9029 16.3732 61.5232 16.3732H32.1714C31.9297 16.3732 31.7059 16.2465 31.5818 16.0394L29.4035 12.4091C29.2792 12.202 29.0554 12.0752 28.814 12.0752H15.6221C15.2424 12.0752 14.9346 12.3833 14.9346 12.763Z" fill={primary_dark} />
      <path d="M57.3974 20.8423H19.5762V64.1646H57.3974V20.8423Z" fill="url(#paint0_linear_16236_1770)"/>
      <path d="M59.4606 25.313H17.8574V64.1656H59.4606V25.313Z" fill="url(#paint1_linear_16236_1770)"/>
      <path d="M60.3205 28.4072H16.6543V64.1654H60.3205V28.4072Z" fill="white"/>
      <path d="M10.573 32.6265C10.5157 32.2132 10.8369 31.8447 11.2542 31.8447H65.7278C66.142 31.8447 66.4623 32.2083 66.4098 32.6195L62.4596 63.5638C62.4156 63.9074 62.1236 64.1646 61.7776 64.1646H15.5337C15.1904 64.1646 14.8996 63.9114 14.8526 63.5711L10.573 32.6265Z" fill={primary} />
      <path d="M61.8935 73.2255C69.3962 73.2255 75.4784 67.1433 75.4784 59.6406C75.4784 52.1378 69.3962 46.0557 61.8935 46.0557C54.3908 46.0557 48.3086 52.1378 48.3086 59.6406C48.3086 67.1433 54.3908 73.2255 61.8935 73.2255Z" fill={primary_light} />
      <path d="M65.1275 58.2455L65.1275 67.375L61.9465 67.375L58.6301 67.375L58.6301 58.2455L55.9228 58.2455L61.9465 51.233L68.1055 58.2455L65.1275 58.2455Z" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear_16236_1770" x1="38.4866" y1="20.8423" x2="38.4866" y2="64.1646" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="#4F4F4F"/>
        </linearGradient>
        <linearGradient id="paint1_linear_16236_1770" x1="38.659" y1="25.313" x2="38.659" y2="64.1656" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="#4F4F4F"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Upload;