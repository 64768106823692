import { ROUTES_KEYS } from '@src/router/route-keys';
import { ROUTES_MAPPING } from '@src/router/route-keys-mapping';

export interface SearchItem {
  title: string
  description: string
  path: string
  category: string
  subCategory?: string
  synonyms?: string[]
  actions?: {
    name: string
    description: string
    path: string
    synonyms?: string[]
  }[]
}

export const BASE_PATH_PREFIX = '/tms';

export const addTmsPrefix = (value: string) => {
  return `${BASE_PATH_PREFIX}${value || ''}`;
};

export const searchData: SearchItem[] = [
  // Dashboard Category
  {
    title: 'Dashboard',
    description: 'View your main dashboard and overview',
    path: ROUTES_MAPPING[ROUTES_KEYS.HOME],
    category: 'Dashboard',
    synonyms: ['home', 'overview', 'main'],
  },
  {
    title: 'Analytics Dashboard',
    description: 'View business analytics and insights',
    path: ROUTES_MAPPING[ROUTES_KEYS.DASHBOARD],
    category: 'Dashboard',
    synonyms: ['metrics', 'statistics', 'performance', 'insights', 'OMS/WMS Analytics Dashboard', 'TMS Analytics Dashboard'],
    actions: [
      {
        name: 'OMS/WMS Analytics Dashboard',
        description: 'View business analytics and insights for OMS/WMS',
        path: ROUTES_MAPPING[ROUTES_KEYS.DASHBOARD],
      },
      {
        name: 'TMS Analytics Dashboard',
        description: 'View business analytics and insights for TMS',
        path: ROUTES_MAPPING[ROUTES_KEYS.DASHBOARD_ANALYTICS],
      }
    ],
  },

  // Orders Category
  {
    title: 'Live Orders',
    description: 'View and manage incoming orders in real-time',
    path: ROUTES_MAPPING[ROUTES_KEYS.LIVE_ORDERS],
    category: 'Orders',
    synonyms: ['incoming orders', 'new orders', 'current orders', 'active orders','orders'],
    actions: [
      {
        name: 'New Orders',
        description: 'View and manage new orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.LIVE_ORDERS]}?currentTab=new_orders`,
        synonyms: ['recent orders', 'latest orders', 'pending orders', 'unprocessed orders']
      },
      {
        name: 'Picking',
        description: 'View and manage picking orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.LIVE_ORDERS]}?currentTab=picking`,
        synonyms: ['pick orders', 'order picking', 'pick list', 'pick items']
      },
      {
        name: 'Consolidation',
        description: 'View and manage consolidation orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.LIVE_ORDERS]}?currentTab=consolidation`,
      },
      {
        name: 'Ready to Pack',
        description: 'View and manage ready to pack orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.LIVE_ORDERS]}?currentTab=packing`,
      },
      {
        name: 'Packed',
        description: 'View and manage packed orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.LIVE_ORDERS]}?currentTab=packed`,
      },
      {
        name: 'Ready to Ship',
        description: 'View and manage ready to ship orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.LIVE_ORDERS]}?currentTab=ready_to_ship`,
      },
      {
        name: 'All Orders',
        description: 'View all orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.LIVE_ORDERS]}?currentTab=all`,
      },
      {
        name: 'Shipped',
        description: 'View and manage shipped orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.LIVE_ORDERS]}?currentTab=shipped`,
      },
    ],
  },
  // Create Order Category
  {
    title: 'Create Order',
    description: 'Create new orders, either single or bulk',
    path: ROUTES_MAPPING[ROUTES_KEYS.CREATE_ORDER],
    category: 'Orders',
    actions: [
      {
        name: 'Create Single Order',
        description: 'Create a new single order',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.CREATE_ORDER]}?orderCreationType=single`,
      },
      {
        name: 'Create Bulk Order',
        description: 'Create a new bulk order',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.CREATE_ORDER]}?orderCreationType=bulk`,
      },
      {
        name: 'Return To Sender',
        description: 'Create an order to return to sender',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.CREATE_ORDER]}?currentTab=return_to_sender`,
        
      },
    ]
  },
  // Pending Actions Category
  {
    title: 'Pending Actions',
    description: 'View and manage orders requiring attention',
    path: ROUTES_MAPPING[ROUTES_KEYS.PENDING_ACTIONS],
    category: 'Orders',
    synonyms: ['on hold', 'action required', 'attention needed'],
    actions: [
      {
        name: 'Split Orders',
        description: 'View and manage split orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.PENDING_ACTIONS]}?currentTab=splitted_orders`,
      },
      {
        name: 'Order For Approval',
        description: 'View and manage orders pending approval',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.PENDING_ACTIONS]}?currentTab=orders_for_approval`,
      }
    ],
  },
  // Stock Transfer Requests Category
  {
    title: 'Stock Transfer Requests',
    description: 'Manage stock transfer between locations',
    path: ROUTES_MAPPING[ROUTES_KEYS.STOCK_TRANSFER_REQUESTS],
    category: 'Orders',
    synonyms: ['transfer requests', 'stock movement', 'inventory transfer', 'STO', 'STR'],
    actions: [
      {
        name: 'Pending Stock Transfer Requests',
        description: 'View and manage pending stock transfer requests',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.STOCK_TRANSFER_REQUESTS]}?currentTab=pending-request`,
      },
      {
        name: 'Accepted Stock Transfer Requests',
        description: 'View and manage accepted stock transfer requests',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.STOCK_TRANSFER_REQUESTS]}?currentTab=accepted`,
      },
      {
        name: 'Cancelled Stock Transfer Requests',
        description: 'View and manage cancelled stock transfer requests',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.STOCK_TRANSFER_REQUESTS]}?currentTab=cancelled`,
      }
    ]
  },
  // Manifest Category
  {
    title: 'Manifest',
    description: 'View and manage shipping manifests',
    path: ROUTES_MAPPING[ROUTES_KEYS.MANIFEST],
    category: 'Orders',
    synonyms: ['shipping manifest', 'delivery manifest', 'dispatch list'],
    actions: [
      {
        name: 'Draft Manifest',
        description: 'View and manage draft manifests',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.MANIFEST]}?currentTab=draft`,
      },
      {
        name: 'Ready for handover Manifest',
        description: 'View and manage manifests ready for handover',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.MANIFEST]}?currentTab=ready_for_handover`,
      },
      {
        name: 'Completed Manifest',
        description: 'View and manage completed manifests',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.MANIFEST]}?currentTab=completed`,
      },
    ],
  },
  // Picking Wave Category
  {
    title: 'Picking Wave',
    description: 'Create and manage picking waves',
    path: ROUTES_MAPPING[ROUTES_KEYS.CREATE_PICKING_WAVE],
    category: 'Orders',
    synonyms: ['batch picking', 'wave picking', 'pick lists'],
    actions: [
      {
        name: 'Ongoing Picking Wave',
        description: 'View and manage ongoing picking waves',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.CREATE_PICKING_WAVE]}?tab=1`,
      },
      {
        name: 'Completed Picking Wave',
        description: 'View and manage completed picking waves',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.CREATE_PICKING_WAVE]}?tab=2`,
      }
    ]
  },
  // Tax Invoices Category
  {
    title: 'Tax Invoices',
    description: 'Manage and view tax invoices',
    path: ROUTES_MAPPING[ROUTES_KEYS.TAX_INVOICES],
    category: 'Orders',
    synonyms: ['invoices', 'billing', 'tax documents'],
  },
  //  Shipments
  {
    title:'Forward Shipments',
    description: 'View and manage forward shipments',
    path: ROUTES_MAPPING[ROUTES_KEYS.FORWARD_SHIPMENT],
    category: 'Forward Shipments',
    synonyms: ['shipments', 'delivery', 'parcels', 'tracking', 'shipment status', 'delivery status', 'shipment tracking','shipment orders','delivery tracking'],
  },
  {
    title: 'Return Shipment',
    description: 'View and manage return shipments',
    path: ROUTES_MAPPING[ROUTES_KEYS.RETURN_SHIPMENT],
    category: 'Return Shipments',
    synonyms: ['return shipments', 'return delivery', 'return parcels', 'return tracking', 'return status'],
    actions: [
      {
        name: 'Customer Return Shipments',
        description: 'View and manage customer return shipments',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.RETURN_SHIPMENT]}?types=post_delivery`,
      },
      {
        name: 'Courier Partner Return Shipments',
        description: 'View and manage courier partner return shipments',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.RETURN_SHIPMENT]}?currentTab=courier_return`,
      }
    ]
  },
  {
    title: 'Shipments Tracking',
    description: 'Track shipment status and location',
    path: ROUTES_MAPPING[ROUTES_KEYS.SHIPMENTS_TRACKING],
    category: 'Shipments Tracking',
    synonyms: ['tracking', 'shipment status', 'delivery tracking', 'parcel tracking'],
  },
  // Shipment Orders Category
  {
    title: 'Order Status',
    description: 'Create and manage shipment orders',
    path: ROUTES_MAPPING[ROUTES_KEYS.ORDER_STATUS],
    category: 'Order Status',
    synonyms: ['order tracking', 'shipment status', 'delivery status', 'order progress', 'order phase'],
    actions: [
      {
        name: 'New Orders',
        description: 'View and manage new orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.ORDER_STATUS]}?currentTab=new_orders`,
      },
      {
        name: 'Ready to Ship',
        description: 'View and manage ready to ship orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.ORDER_STATUS]}?currentTab=ready_to_ship`,
      },
      {
        name: 'Shipped',
        description: 'View and manage shipped orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.ORDER_STATUS]}?currentTab=dispatched`,
      },
      {
        name: 'In Transit',
        description: 'View and manage orders in transit',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.ORDER_STATUS]}?currentTab=in_transit`,
      },
      {
        name: 'Out for Delivery',
        description: 'View and manage orders out for delivery',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.ORDER_STATUS]}?currentTab=out_for_delivery`,
      },
      {
        name: 'Return in Progress',
        description: 'View and manage orders in return process',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.ORDER_STATUS]}?currentTab=return_in_progress`,
      },
      {
        name: 'Cancelled',
        description: 'View and manage cancelled orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.ORDER_STATUS]}?currentTab=cancelled`,
      },
      {
        name: 'All',
        description: 'View and manage all orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.ORDER_STATUS]}?currentTab=all`,
      }
    ],
  },
  {
    title: 'Create Shipment Order',
    description: 'Create single and bulk shipment orders',
    path: ROUTES_MAPPING[ROUTES_KEYS.CREATE_SHIPMENT_ORDER],
    category: 'Create Shipment Order',
    synonyms: ['create shipment', 'new shipment order', 'shipment order', 'generate shipment'],
  },
  {
    title: 'Pickup Locations',
    description: 'Manage shipment pickup locations',
    path: ROUTES_MAPPING[ROUTES_KEYS.PICKUP_LOCATIONS],
    category: 'Pickup Locations',
    synonyms: ['pickup locations', 'shipment locations','shipment orders' ,'collection points', 'pickup spots'],
  },
  // Bulk Ship Category
  {
    title: 'Bulk Ship',
    description: 'View and manage bulk shipment orders',
    path: ROUTES_MAPPING[ROUTES_KEYS.BULK_SHIP],
    category: 'Bulk Ship',
    synonyms: ['bulk shipment', 'bulk shipment orders', 'clusters', 'shipment clusters'],
    actions: [
      {
        name: 'Suggestions',
        description: 'View and manage bulk shipping suggestions',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.BULK_SHIP]}?tab=suggestions`,
      },
      {
        name: 'Saved Suggestions',
        description: 'View and manage saved bulk shipping suggestions',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.BULK_SHIP]}?tab=saved_suggestions`,
      },
      {
        name: 'Clusters',
        description: 'View and manage shipping clusters',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.BULK_SHIP]}?tab=clusters`,
      }
    ],
  },

  // Returns Category
  {
    title: 'Return Requests',
    description: 'View and manage return requests',
    path: ROUTES_MAPPING[ROUTES_KEYS.RETURN_REQUESTS],
    category: 'Returns',
    synonyms: ['return requests', 'return','return orders requests'],
    actions: [
      {
        name: 'Pending Return Requests',
        description: 'Manage pending return requests',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.RETURN_REQUESTS]}?status=new`,
      },
      {
        name: 'Cancelled Return Requests',
        description: 'Manage cancelled return requests',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.RETURN_REQUESTS]}?status=rejected`,
      },
      {
        name: 'Create Return Request',
        description: 'Create a new return request',
        path: ROUTES_MAPPING[ROUTES_KEYS.CREATE_RETURN_REQUEST],
      },
    ],
  },
  {
    title: 'Return Order',
    description: 'Process and manage different types of return orders',
    path: ROUTES_MAPPING[ROUTES_KEYS.RETURN_ORDERS],
    category: 'Returns',
    actions: [
      {
        name: 'Courier Partner Return',
        description: 'Process returns from courier partners',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.RETURN_ORDERS]}?currentTab=courier_partner_return`
      },
      {
        name: 'Customer Return',
        description: 'Process direct customer returns',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.RETURN_ORDERS]}?currentTab=customer_return`,
      },
      {
        name: 'Off System Return',
        description: 'Process offline or manual returns',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.RETURN_ORDERS]}?currentTab=off_system_return`,
      },
      {
        name: 'All Returns',
        description: 'View and manage all types of returns',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.RETURN_ORDERS]}?currentTab=all`,
      },
      {
        name: 'Create Return Order',
        description: 'Create a new return order',
        path: ROUTES_MAPPING[ROUTES_KEYS.CREATE_RETURN_ORDER],
      },
    ],
  },
  {
    title: 'Return Order Processing',
    description: 'Process return orders',
    path: ROUTES_MAPPING[ROUTES_KEYS.RETURN_ORDER_PROCESSING],
    category: 'Returns',
    synonyms: ['return processing', 'order returns', 'RTO processing'],
  },
  {
    title: 'Return Gate Entry',
    description: 'Manage return gate entries',
    path: ROUTES_MAPPING[ROUTES_KEYS.RETURN_GATE_ENTRY],
    category: 'Returns',
    synonyms: ['gate entry', 'return entry', 'RTO entry','return GRN'],
  },
  //ORDER SETTING
  {
    title: 'OMS Settings',
    description: 'Configure order management system settings',
    path: ROUTES_MAPPING[ROUTES_KEYS.OMS_SETTINGS],
    category: 'OMS Settings',
    synonyms: ['order management settings', 'OMS configuration', 'order settings'],
    actions: [
      {
        name: 'Hub OMS Settings',
        description: 'Configure hub-specific settings',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMS_SETTINGS]}?currentTab=hub_setting`,
      },
      {
        name: 'Seller OMS Settings',
        description: 'Configure seller-specific settings',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMS_SETTINGS]}?currentTab=seller_setting`,
      }
    ]
  },
  {
    title: 'Order Tags',
    description: 'Create and manage order tags',
    path: ROUTES_MAPPING[ROUTES_KEYS.ORDER_TAGS],
    category: 'Order Tags',
    synonyms: ['order labels', 'tag management', 'order categorization', 'order tagging']
  },
  {
    title: 'Shipping Automation',
    description: 'Configure shipping automation rules',
    path: ROUTES_MAPPING[ROUTES_KEYS.SHIPPING_AUTOMATION],
    category: 'Shipping Automation',
    synonyms: ['shipping rules', 'automation settings', 'settings', 'shipping automation'],
    actions: [
      {
        name: 'Forward Shipping Automation',
        description: 'Configure forward shipping automation rules',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.SHIPPING_AUTOMATION]}?tab=eligibility`,
      },
      {
        name: 'Return Shipping Automation',
        description: 'Configure return shipping automation rules',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.SHIPPING_AUTOMATION]}?tab=return_eligibility`,
      },
      {
        name: 'Priority Shipping Automation',
        description: 'Configure priority shipping automation rules',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.SHIPPING_AUTOMATION]}?tab=priority`,
      },
      {
        name: 'Omniship Automation',
        description: 'Configure Omniship automation rules',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.SHIPPING_AUTOMATION]}?tab=shipping_aggregator`,
      }
    ]
  },
  // Automation Rules Category
  {
    title: 'Packaging Automation',
    description: 'Set automated packaging rules',
    path: ROUTES_MAPPING[ROUTES_KEYS.PACKAGING_AUTOMATION],
    category: 'Packaging Automation',
    synonyms: ['packaging rules', 'automation settings', 'Automation rules','settings', 'packaging automation', 'automated packaging'],
  },
  {
    title: 'Hub Routing Automation',
    description: 'Configure automated hub routing',
    path: ROUTES_MAPPING[ROUTES_KEYS.HUB_ROUTING_AUTOMATION],
    category: 'Hub Routing Automation',
    synonyms: ['hub routing', 'automated routing', 'Automation rules','routing automation', 'settings', 'hub automation'],
  },
  {
    title: 'Gifting Automation',
    description: 'Configure gifting automation rules',
    path: ROUTES_MAPPING[ROUTES_KEYS.GIFTING_AUTOMATION],
    category: 'Gifting Automation',
    synonyms: ['gifting rules', 'gift automation', 'settings', 'Automation rules','automated gifting', 'gift settings'],
  },
  {
    title: 'Omniship Automation',
    description: 'Configure omniship automation rules',
    path: ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_AUTOMATION],
    category: 'Omniship Automation',
    synonyms: ['omniship rules', 'automated omniship', 'Automation rules', 'omniship automation', 'omniship settings'],
  },
  // Manage Invoice Category
  {
    title: 'Manage Invoice',
    description: 'View and manage invoices',
    path: ROUTES_MAPPING[ROUTES_KEYS.MANAGE_INVOICE],
    category: 'Manage Invoice',
    synonyms: ['invoice management', 'billing', 'invoice settings', 'manage billing']
  },
  //Apps and Integrations Category
  {
    title: 'Sales Channel Apps',
    description: 'Manage sales channel settings and configurations',
    path: ROUTES_MAPPING[ROUTES_KEYS.SALES_CHANNEL],
    category: 'Sales Channel',
    synonyms: ['sales channel', 'channel management', 'sales settings', 'channel configuration','Apps & Integrations'],
  },
  {
    title: 'Shipping Apps',
    description: 'Manage shipping apps settings and configurations',
    path: ROUTES_MAPPING[ROUTES_KEYS.SHIPPING_APPS],
    category: 'Shipping Apps',
    synonyms: ['shipping apps', 'apps', 'shipping settings', 'apps settings','Apps & Integrations'],
  },
  {
    title: 'Tax Authority',
    description: 'Manage tax authority settings and configurations',
    path: ROUTES_MAPPING[ROUTES_KEYS.TAX_AUTHORITY],
    category: 'Tax Authority',
    synonyms: ['tax authority', 'tax settings', 'tax management', 'tax configuration','Apps & Integrations'],
  },
  {
    title: 'Custom Apps',
    description: 'Manage custom app settings and configurations',
    path: ROUTES_MAPPING[ROUTES_KEYS.CUSTOM_APPS],
    category: 'Custom Apps',
    synonyms: ['custom apps', 'app management', 'custom integrations', 'Apps & Integrations'],
  },

  // Reports Category
  {
    title: 'Reports',
    description: 'View and manage reports',
    path: ROUTES_MAPPING[ROUTES_KEYS.REPORTS],
    category: 'Reports',
    synonyms: ['reports', 'report', 'analytics', 'statistics', 'data', 'metrics', 'insights', 'dashboards', 'summaries', 'exports', 'downloads'],
    actions: [
      {
        name: 'New Report',
        description: 'Create, customize and generate detailed business reports',
        path: ROUTES_MAPPING[ROUTES_KEYS.REPORTS],
      },
      {
        name: 'My Generated Reports',
        description: 'View and access your generated reports',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.REPORTS]}?tab=myreports`,
      },
      {
        name: 'Archived Reports',
        description: 'View and access previously archived reports',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.REPORTS]}?tab=archive`,
      },
    ],
  },

  // Hubs Category
  {
    title: 'Hub Management',
    description: 'Manage warehouse hubs and operations',
    path: ROUTES_MAPPING[ROUTES_KEYS.HUBS],
    category: 'Hubs',
    synonyms: ['warehouse','all hubs', 'storage', 'facility', 'retail', 'hub', 'dark store','hub management'],
  },
  {
    title: 'All Hubs Delivery Zones',
    description: 'Manage delivery zones for all hubs',
    path: `${ROUTES_MAPPING[ROUTES_KEYS.HUBS]}?currentTab=all_delivery_zones`,
    category: 'Hubs',
    synonyms: ['all delivery zones', 'delivery zones','storage', 'zones', 'retail', 'hub','hub management'],
  },
  {
    title: 'Hub Management Overview',
    description: 'Manage warehouse hubs and operations',
    path: ROUTES_MAPPING[ROUTES_KEYS.HUB_OVERVIEW],
    category: 'Hubs',
    synonyms: ['warehouse', 'storage', 'retail', 'hub', 'hub setup', 'hub overview','hub management'],
  },
  {
    title: 'Hub Location',
    description: 'View and manage specific hub locations',
    path: ROUTES_MAPPING[ROUTES_KEYS.HUB_LOCATIONS],
    category: 'Hubs',
    synonyms: ['warehouse location', 'hub setup', 'storage location', 'locations', 'hub', 'dark store','hub management'],
  },
  {
    title: 'Hub Zones',
    description: 'Manage hub zones',
    path: ROUTES_MAPPING[ROUTES_KEYS.HUB_ZONES],
    category: 'Hubs',
    synonyms: ['zones', 'delivery zones', 'hub setup', 'hub zones','hub management'],
  },
  {
    title: 'Hub Delivery Zones',
    description: 'Manage hub delivery zones',
    path: ROUTES_MAPPING[ROUTES_KEYS.HUB_DELIVERY_ZONE],
    category: 'Hubs',
    synonyms: ['zones', 'delivery zones','hub setup', 'hub zones','hub management'],
  },
  {
    title: 'Hub Bins',
    description: 'Manage storage bins within the hub',
    path: ROUTES_MAPPING[ROUTES_KEYS.HUB_BINS],
    category: 'Hub Assets',
    synonyms: ['bins', 'hub bins','hub assets', 'storage', 'bin locations', 'warehouse bins'],
  },
  {
    title: 'Hub Carts',
    description: 'Manage storage carts within the hub',
    path: ROUTES_MAPPING[ROUTES_KEYS.HUB_CARTS],
    category: 'Hub Assets',
    synonyms: ['bins','Pallets','boxes','hub assets', 'storage', 'warehouse carts'],
  },
  {
    title: 'Hub Pallets',
    description: 'Manage Pallets within the hub',
    path: ROUTES_MAPPING[ROUTES_KEYS.HUB_PALLETS],
    category: 'Hub Assets',
    synonyms: ['pallets','boxes', 'hub assets','carts', 'storage pallets', 'bins', 'warehouse '],
  },
  {
    title: 'Hub Boxes',
    description: 'Manage Boxes within the hub',
    path: ROUTES_MAPPING[ROUTES_KEYS.HUB_BOXES],
    category: 'Hub Assets',
    synonyms: ['boxes','pallets', 'bins', 'hub assets', 'storage', 'warehouse bins'],
  },
  // Inventory Category
  {
    title: 'Inventory Reports',
    description: 'View inventory reports and status',
    path: ROUTES_MAPPING[ROUTES_KEYS.TOTAL_INVENTORY],
    category: 'Inventory',
    subCategory: 'Reports',
    synonyms: ['stock reports', 'inventory status'],
    actions: [
      {
        name: 'Total Inventory',
        description: 'View complete inventory status',
        path: ROUTES_MAPPING[ROUTES_KEYS.TOTAL_INVENTORY],
      },
      {
        name: 'Location Inventory',
        description: 'View inventory by location',
        path: ROUTES_MAPPING[ROUTES_KEYS.LOCATION_INVENTORY],
      },
      {
        name: 'SKU Locations',
        description: 'Track SKUs across locations',
        path: ROUTES_MAPPING[ROUTES_KEYS.SKU_LOCATIONS],
      },
      {
        name: 'Batches',
        description: 'Manage inventory batches',
        path: ROUTES_MAPPING[ROUTES_KEYS.INVENTORY_BATCHES],
      },
    ],
  },

  {
    title: 'Gate Entry',
    description: 'Create and manage gate entries',
    path: ROUTES_MAPPING[ROUTES_KEYS.GATE_ENTRY],
    category: 'Inventory',
    subCategory: 'Operations',
    synonyms: ['stock entry', 'inventory management', 'inward inventory', 'warehouse entry']
  },
  {
    title: 'Grn',
    description: 'Manage and Create new GRN',
    path: ROUTES_MAPPING[ROUTES_KEYS.GRN],
    category: 'Inventory',
    subCategory: 'Operations',
    synonyms: ['create grn', 'inventory management', 'receipt', 'inward stock', 'new grn']
  },
  {
    title: 'Putaway',
    description: 'Manage bin putaway ',
    path: ROUTES_MAPPING[ROUTES_KEYS.PUTAWAY],
    category: 'Inventory',
    synonyms: ['putaway', 'item putaway', 'bin putaway', 'cancelled putaway', 'inventory management'],
    actions: [
      {
        name: 'Bin Putaway',
        description: 'Manage bin putaway',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.PUTAWAY]}?tab=bin-putaway`,
      }, {
        name: 'Item Putaway',
        description: 'Manage item putaway',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.PUTAWAY]}?tab=item-putaway`,
      },
    ]
  },
  {
    title: 'Pending Putaway',
    description: 'View grn pending putaway tasks',
    path: ROUTES_MAPPING[ROUTES_KEYS.PENDING_PUTAWAY],
    category: 'Inventory',
    synonyms: ['pending putaway','adjusted inventory', 'putaway', 'bin putaway', 'item putaway', 'cancelled putaway', 'grn putaway'],
    actions: [
      {
        name: 'Cancelled ItemPutaway',
        description: 'View cancelled putaway tasks',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.PENDING_PUTAWAY]}?tab=1`,
      },
      {
        name: 'Grn Pending Putaway',
        description: 'View grn pending putaway tasks',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.PENDING_PUTAWAY]}?tab=2`,
      },
      {
        name: 'Adjust Inventory',
        description: 'View adjusted inventory tasks',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.PENDING_PUTAWAY]}?tab=3`,
      }
    ]
  },
  {
    title: 'Cycle Count',
    description: 'Perform inventory cycle counts',
    path: ROUTES_MAPPING[ROUTES_KEYS.CYCLE_COUNT],
    category: 'Inventory',
    subCategory: 'Operations',
    synonyms: ['inventory count','counting stock', 'inventory management','location off cycle count']
  },
  {
    title: 'Ad-Hoc Usage',
    description: 'Record ad-hoc inventory usage',
    path: ROUTES_MAPPING[ROUTES_KEYS.AD_HOC_USAGE],
    category: 'Inventory',
    subCategory: 'Operations',
    synonyms: ['ad-hoc activity', 'stock adjustment','inventory adjustment']
  },
  {
    title: 'Stock Transfer',
    description: 'View and manage stock transfers',
    path: ROUTES_MAPPING[ROUTES_KEYS.STOCK_TRANSFER],
    category: 'Inventory',
    subCategory: 'Operations',
    synonyms: ['stock transfer', 'inventory management', 'transfer','stock transfer request']
  },
  {
    title: 'Expired Inventory Task',
    description: 'View expired inventory tasks',
    path: ROUTES_MAPPING[ROUTES_KEYS.EXPIRED_INVENTORY_TASK],
    category: 'Inventory',
    synonyms: ['expired inventory', 'inventory management', 'adjust inventory']
  },
  // Catalog Category
  {
    title: 'Catalog Management',
    description: 'Manage product catalog',
    path: ROUTES_MAPPING[ROUTES_KEYS.CATALOG_SKUS],
    category: 'Catalog',
    synonyms: ['products', 'items', 'SKUs', 'kits', 'archived SKUs', 'archived kits', 'catalog',  'product catalog', 'item catalog', 'product management', 'SKU management', 'kit management'],
    actions: [
      {
        name: 'SKUs',
        description: 'View and manage SKUs',
        path: ROUTES_MAPPING[ROUTES_KEYS.CATALOG_SKUS],
      },
      {
        name: 'Archived SKUs',
        description: 'View and manage archived SKUs',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.CATALOG_SKUS]}?status=archived`,
      },
      {
        name: 'Kits',
        description: 'View and manage Kits',
        path: ROUTES_MAPPING[ROUTES_KEYS.CATALOG_KITS],
      },
      {
        name: 'Archived Kits',
        description: 'View and manage archived Kits',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.CATALOG_KITS]}?status=archived`,
      },
      {
        name: 'Sales Channel Listing',
        description: 'Manage product listings across sales channels',
        path: ROUTES_MAPPING[ROUTES_KEYS.SALES_CHANNEL_LISTING],
      },
      {
        name: 'Catalog Comparison',
        description: 'Compare product data across catalogs',
        path: ROUTES_MAPPING[ROUTES_KEYS.CATALOG_COMPARISION],
      },
      {
        name: 'New Catalog Comparison',
        description: 'Create a new catalog comparison',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.CATALOG_COMPARISION]}?tab=new_comparison`,
      },
      {
        name: 'Previous Catalog Comparisons',
        description: 'View previous catalog comparisons',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.CATALOG_COMPARISION]}?tab=previous_comparison`,
      },
      {
        name: 'Embedded Barcode Configuration',
        description: 'Set up barcode formats and rules',
        path: ROUTES_MAPPING[ROUTES_KEYS.CATALOG_EMBEDDED_BARCODE],
      },
    ],
  },

  // Purchases Category

  {
    title: 'Purchases',
    description: 'Manage purchase orders',
    category: 'Purchases',
    path: ROUTES_MAPPING[ROUTES_KEYS.PURCHASE_ORDERS],
    synonyms: ['purchase orders', 'purchase shipments', 'vendors'],
    actions: [
      {
        name: 'Purchase Orders',
        description: 'Manage purchase orders',
        path: ROUTES_MAPPING[ROUTES_KEYS.PURCHASE_ORDERS],
      }, {
        name: 'Purchase Shipments',
        description: 'Manage purchase shipments',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.PURCHASE_ORDERS]}?currentTab=po_shipments`,
      },
    ],
  },

  {
    title: 'Stock Ownership Transfer',
    description: 'Transfer stock ownership',
    path: ROUTES_MAPPING[ROUTES_KEYS.STOCK_OWNERSHIP_TRANSFER],
    category: 'Purchases',
    synonyms: ['procurement', 'buying', 'vendors'],
   
  },
  {
    title: 'Suppliers',
    description: 'Manage suppliers',
    path: ROUTES_MAPPING[ROUTES_KEYS.ALL_SUPPLIERS],
    category: 'Purchases',
    synonyms: ['procurement', 'buying', 'vendors'],
  },

  // Sellers
  {
    title: 'Sellers',
    description: 'View and manage sellers',
    path: ROUTES_MAPPING[ROUTES_KEYS.SELLERS],
    category: 'Sellers',
    synonyms: ['sellers', 'vendors', 'suppliers'],
  },

  // Settings Category
  {
    title: 'User Profile',
    description: 'Update profile and change password',
    path: ROUTES_MAPPING[ROUTES_KEYS.CHANGE_PASSWORD],
    category: 'Settings',
    synonyms: ['settings', 'apps', 'user profile', 'profile', 'user', 'apps & integration'],
  },
  {
    title: 'Shipping Partner Cities',
    description: 'Configure shipping partner cities',
    path: ROUTES_MAPPING[ROUTES_KEYS.SHIPPING_PARTNER_CITIES],
    category: 'Settings',
    synonyms: ['settings', 'apps', 'shipping partner cities', 'cities', 'partner', 'shipping partner', 'apps & integration'],
  },
  {
    title : 'Company Settings',
    description: 'Configure company settings',
    path: ROUTES_MAPPING[ROUTES_KEYS.COMPANY_SETTINGS],
    category: 'Settings',
    synonyms: ['settings', 'apps', 'company settings', 'company', 'apps & integration'],
  },
  {
    title : 'User Settings',
    description: 'Manage user accounts',
    path:  ROUTES_MAPPING[ROUTES_KEYS.USER_SETTINGS],
    category: 'Settings',
    synonyms: ['settings', 'apps', 'user settings', 'user', 'apps & integration'],
  },
  {
    title : 'Role Settings',
    description: 'Manage user roles',
    path: ROUTES_MAPPING[ROUTES_KEYS.ROLE_SETTINGS],
    category: 'Settings',
    synonyms: ['settings', 'apps', 'role settings', 'role', 'apps & integration'],
  },
  {
    title: 'Hub SKU Configurations',
    description: 'Configure SKU configurations for hubs',
    path: ROUTES_MAPPING[ROUTES_KEYS.SKU_CONFIGURATIONS],
    category: 'Settings',
    synonyms: ['settings', 'configurations', 'hub sku configurations', 'hub', 'sku'],
  },
  {
    title: 'Ad Hoc Activities',
    description: 'Create and view adhoc activities',
    path: ROUTES_MAPPING[ROUTES_KEYS.ADHOC_ACTIVITIES],
    category: 'Settings',
    synonyms: ['settings', 'ad hoc activities', 'adhoc', 'hoc', 'activities'],
  },
  {
    title: 'Customers',
    description: 'Create and manage customers',
    path: ROUTES_MAPPING[ROUTES_KEYS.CUSTOMERS],
    category: 'Settings',
    synonyms: ['settings', 'customers', 'customer'],
  },
  {
    title: 'Shipping Automation',
    description: 'Configure shipping automation rules',
    path: `${ROUTES_MAPPING[ROUTES_KEYS.SHIPPING_AUTOMATION]}?tab=eligibility`,
    category: 'Settings',
    synonyms: ['settings', 'shipping automation', 'shipping', 'automation'],
  },
  {
    title: 'Bulk SKU Configurations',
    description: 'Configure bulk SKUs',
    path: ROUTES_MAPPING[ROUTES_KEYS.DEFAULT_SKUS_SETTINGS],
    category: 'Settings',
    synonyms: ['settings', 'catalog settings', 'catalog', 'settings', 'apps & integration'],
  },
  {
    title: 'System Cities',
    description: 'Configure system cities',
    path: ROUTES_MAPPING[ROUTES_KEYS.SYSTEM_CITIES],
    category: 'Settings',
    synonyms: ['settings', 'city mapping', 'city', 'mapping', 'apps & integration'],
  },
  {
    title: '3PL Billing',
    description: 'Manage billing profiles, contracts, bills',
    path: ROUTES_MAPPING[ROUTES_KEYS.BILLING_PROFILE],
    category: 'Settings',
    synonyms: ['bills', 'contracts', 'terminated', '3pl', 'pl' ,'3PL Billing', '3PL Billing Profile', '3PL Billing Contracts'],
    actions: [
      { 
        name: 'Live Billing Profile',
        description: 'Configure billing profiles',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.BILLING_PROFILE]}?status=live`,
      },
      {
        name: 'Draft Billing Profile',
        description: 'Configure billing profiles',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.BILLING_PROFILE]}?status=drafts`,
      },
      {
        name: 'Live Contracts',
        description: 'Manage live contracts',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.CONTRACTS]}?currentTabId=live`,
      },
      {
        name: 'Terminated Contracts',
        description: 'Manage terminated contracts',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.CONTRACTS]}?currentTabId=terminated`,
      },
      {
        name: 'Bills',
        description: 'Manage or generate bills',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.BILLS]}`,
      },
    ]
  },

  // Analytics Category
  {
    title: 'Analytics',
    description: 'View business analytics',
    path: ROUTES_MAPPING[ROUTES_KEYS.ANALYTICS_SELLER],
    category: 'Analytics',
    synonyms: ['reports', 'insights', 'metrics'],
    actions: [
      {
        name: 'Seller Analytics',
        description: 'View seller performance',
        path: ROUTES_MAPPING[ROUTES_KEYS.ANALYTICS_SELLER],
      },
      {
        name: 'Hub Analytics',
        description: 'View warehouse performance',
        path: ROUTES_MAPPING[ROUTES_KEYS.ANALYTICS_HUB],
      },
      {
        name: 'Shipping Analytics',
        description: 'View shipping performance',
        path: ROUTES_MAPPING[ROUTES_KEYS.ANALYTICS_SHIPPING],
      },
      {
        name: 'Fulfillment Analytics',
        description: 'View fulfillment metrics',
        path: ROUTES_MAPPING[ROUTES_KEYS.ANALYTICS_FULFILLMENT],
      },
      {
        name: 'Last Mile Analytics',
        description: 'View delivery performance',
        path: ROUTES_MAPPING[ROUTES_KEYS.ANALYTICS_LAST_MILE],
      },
      {
        name: 'POS Analytics',
        description: 'View POS performance',
        path: ROUTES_MAPPING[ROUTES_KEYS.ANALYTICS_POS],
      },
    ],
  },

  // POS Category
  {
    title: 'Point of Sale',
    description: 'Manage POS operations',
    path: ROUTES_MAPPING[ROUTES_KEYS.POS],
    category: 'POS',
    synonyms: ['point of sale', 'retail', 'store'],
    actions: [
      {
        name: 'Registers',
        description: 'Manage POS registers',
        path: ROUTES_MAPPING[ROUTES_KEYS.POS_REGISTERS],
      },
      {
        name: 'Cash Management',
        description: 'Handle cash operations',
        path: ROUTES_MAPPING[ROUTES_KEYS.POS_CASH_MANAGEMENT],
      },
      {
        name: 'POS Orders',
        description: 'View POS orders',
        path: ROUTES_MAPPING[ROUTES_KEYS.POS_ORDERS],
      },
      {
        name: 'POS Settings',
        description: 'Configure POS settings',
        path: ROUTES_MAPPING[ROUTES_KEYS.POS_SETTINGS],
      },
    ],
  },

  {
    title: 'Inventory Overview',
    description: 'View and manage inventory',
    path: ROUTES_MAPPING[ROUTES_KEYS.TOTAL_INVENTORY],
    category: 'Inventory',
    synonyms: ['inventory', 'stock', 'warehouse', 'logistics', 'storage', 'inventory tracking', 'Total Inventory', 'SKU Locations', 'Batch Inventory', 'Location Inventory'],
    actions: [
      {
        name: 'Total Inventory Overview',
        description: 'View and manage total inventory',
        path: ROUTES_MAPPING[ROUTES_KEYS.TOTAL_INVENTORY],
      },
      {
        name: 'SKU Locations Overview',
        description: 'View and manage inventory by SKU',
        path: ROUTES_MAPPING[ROUTES_KEYS.SKU_LOCATIONS],
      },
      {
        name: 'Batch Inventory Overview',
        description: 'View and manage inventory by batch',
        path: ROUTES_MAPPING[ROUTES_KEYS.INVENTORY_BATCHES],
      },
      {
        name: 'Location Inventory Overview',
        description: 'View and manage inventory by location',
        path: ROUTES_MAPPING[ROUTES_KEYS.LOCATION_INVENTORY],
      },
    ],
  },
  // Omniship Category
  {
    title: 'Shipment Orders',
    description: 'Convert shipment orders into shipments at order or package level',
    path: ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_SHIPMENT_ORDERS],
    category: 'Omniship',
    synonyms: ['Omniship', 'Omni Ship', 'Shipping Requests', 'Delivery Orders', 'Dispatch Orders', 'Order Shipments', 'Parcel Processing', 'Created Shipment', 'Omniship Orders', 'Omniship Shipments'],
    actions: [
      // Order View Actions
      {
        name: 'New Orders',
        description: 'View and manage Orders from newly created shipment orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_SHIPMENT_ORDERS]}?current_view=order&current_tab=new`
      },
      {
        name: 'Ready to Ship Orders',
        description: 'Orders prepared for shipment, awaiting carrier pickup',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_SHIPMENT_ORDERS]}?current_view=order&current_tab=ready_to_ship`
      },
      {
        name: 'In Transit Orders',
        description: 'Orders currently moving towards their destination',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_SHIPMENT_ORDERS]}?current_view=order&current_tab=in_transit`
      },
      {
        name: 'At Hub Orders',
        description: 'Orders arrived at a transit or distribution hub',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_SHIPMENT_ORDERS]}?current_view=order&current_tab=at_hub`
      },
      {
        name: 'Out for Delivery Orders',
        description: 'Orders assigned to a driver/Shipping partner and en route to the recipient',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_SHIPMENT_ORDERS]}?current_view=order&current_tab=out_for_delivery`
      },
      {
        name: 'Delivered Orders',
        description: 'Orders successfully received by the recipient',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_SHIPMENT_ORDERS]}?current_view=order&current_tab=delivered`
      },
      {
        name: 'All Orders',
        description: 'View all shipment orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_SHIPMENT_ORDERS]}?current_view=order&current_tab=all`
      },
      // Package View Actions
      {
        name: 'New Packages',
        description: 'View and manage packages from newly created shipment orders',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_SHIPMENT_ORDERS]}?current_view=package&current_tab=new`
      },
      {
        name: 'Ready to Ship Packages',
        description: 'Packages prepared for shipment, awaiting carrier pickup',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_SHIPMENT_ORDERS]}?current_view=package&current_tab=ready_to_ship`
      },
      {
        name: 'In Transit Packages',
        description: 'Packages currently moving towards their destination',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_SHIPMENT_ORDERS]}?current_view=package&current_tab=in_transit`
      },
      {
        name: 'At Hub Packages',
        description: 'Packages arrived at a transit or distribution hub',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_SHIPMENT_ORDERS]}?current_view=package&current_tab=at_hub`
      },
      {
        name: 'Out for Delivery Packages',
        description: 'Packages assigned to a driver/Shipping partner and en route to the recipient',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_SHIPMENT_ORDERS]}?current_view=package&current_tab=out_for_delivery`
      },
      {
        name: 'Delivered Packages',
        description: 'Packages successfully received by the recipient',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_SHIPMENT_ORDERS]}?current_view=package&current_tab=delivered`
      },
      {
        name: 'All Packages',
        description: 'View all shipment packages',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_SHIPMENT_ORDERS]}?current_view=package&current_tab=all`
      }
    ]
  },
  {
    title: 'Create Shipment Order',
    description: 'Create single or bulk shipment orders by entering order and package details',
    path: ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_CREATE_SHIPMENT_ORDER],
    category: 'Omniship',
    synonyms: ['Omniship', 'Omni Ship', 'New Shipping Request', 'Bulk Shipment Creation', 'Order Dispatch', 'Initiate Shipment', 'Create Delivery Order', 'Create Omniship Order', 'New Omniship Shipment']
  },
  {
    title: 'All Shipments',
    description: 'View all shipments with status, tracking, and shipping partner details',
    path: ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_ALL_SHIPMENTS],
    category: 'Omniship',
    synonyms: ['Omniship', 'Omni Ship', 'Shipment List', 'Complete Shipments', 'Delivery Overview', 'Tracking Dashboard', 'Shipment History', 'All Omniship Shipments', 'Omniship Tracking']
  },
  {
    title: 'Pickup Address',
    description: 'Location where the shipment is collected for dispatch',
    path: ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_PICKUP_ADDRESS],
    category: 'Omniship',
    synonyms: ['Omniship', 'Omni Ship', 'Collection Point', 'Sender Address', 'Pickup Location', 'Dispatch Origin', 'Warehouse Pickup', 'Omniship Pickup', 'Omniship Collection'],
    actions: [
      {
        name: 'Add Pickup Location',
        description: 'Add a new pickup location for shipments',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_PICKUP_ADDRESS]}/add`
      },
      {
        name: 'Manage Pickup Locations',
        description: 'View and manage all pickup locations',
        path: ROUTES_MAPPING[ROUTES_KEYS.OMNISHIP_PICKUP_ADDRESS]
      }
    ]
  },

  // TMS Category
  {
    title: 'Shipments',
    description: 'Track, manage, and create shipments seamlessly.',
    path: ROUTES_MAPPING[ROUTES_KEYS.TMS_ALL_SHIPMENTS],
    category: 'Shipments',
    synonyms: ['new shipment', 'cancelled shipment', 'recent shipment'],
    actions: [
      {
        name: 'All Shipments',
        description: 'Monitor status and track packages.',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_ALL_SHIPMENTS],
      },
      {
        name: 'Create Shipments',
        description: 'Create single or bulk shipments effortlessly.',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_CREATE_SHIPMENT],
      },
    ],
  },

  {
    title: 'Trips',
    description: 'Optimize routes, assign drivers, and track trips in real-time.',
    path: ROUTES_MAPPING[ROUTES_KEYS.TMS_CREATE_TRIP],
    category: 'Trips',
    synonyms: ['new trips', 'live trip', 'pending trips', 'past trips', 'create trip'],
    actions: [
      {
        name: 'Planned Routes',
        description: 'Predefine delivery paths for efficiency.',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_PLANNED_ROUTES],
        synonyms: ['route planning', 'delivery paths', 'planned trips', 'route optimization']
      },
      {
        name: 'All Trips',
        description: 'Access all trips, including pending, live, and completed.',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_ALL_TRIPS],
        synonyms: ['view trips', 'trip list', 'trip history', 'completed trips']
      },
    ],
  },

  {
    title: 'Create Trips',
    description: 'Optimize routes, assign drivers, and track trips in real-time.',
    path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_CREATE_TRIP]}?map_view=false&tab=client_trip_enabled`,
    category: 'Create Trips',
    synonyms: ['first mile', 'mid mile', 'last mile', 'hyper local', 'services', 'create trip', 'trip', 'trips'],
    actions: [
      {
        name: 'First Mile Table View',
        description: 'Manage pickups from sellers/clients to hubs.',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_CREATE_TRIP]}?map_view=false&tab=client_trip_enabled`,
        synonyms: ['first mile', 'first mile table view', 'create trip']
      },
      {
        name: 'First Mile Map View',
        description: 'Manage pickups from sellers/clients to hubs.',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_CREATE_TRIP]}?map_view=true&tab=client_trip_enabled`,
        synonyms: ['first mile', 'first mile map view', 'create trip']
      },
      {
        name: 'Mid Mile Table View',
        description: 'Transfer shipments between hubs',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_CREATE_TRIP]}?map_view=false&tab=interhub_trip_enabled`,
        synonyms: ['mid mile', 'mid mile table view', 'create trip']
      },
      {
        name: 'Mid Mile Map View',
        description: 'Transfer shipments between hubs',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_CREATE_TRIP]}?map_view=true&tab=interhub_trip_enabled`,
        synonyms: ['mid mile', 'mid mile map view', 'create trip']
      },
      {
        name: 'Last Mile Table View',
        description: 'Deliver orders to customers',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_CREATE_TRIP]}?map_view=false&tab=customer_trip_enabled`,
        synonyms: ['last mile', 'last mile table view', 'create trip']
      },
      {
        name: 'Last Mile Map View',
        description: 'Deliver orders to customers',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_CREATE_TRIP]}?map_view=true&tab=customer_trip_enabled`,
        synonyms: ['last mile', 'last mile map view', 'create trip']
      },
      {
        name: 'Hyper Local Table View',
        description: 'Enable fast, short-distance deliveries',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_CREATE_TRIP]}?map_view=false&tab=hyperlocal_trip_enabled`,
        synonyms: ['hyper local', 'hyper local table view', 'create trip']
      },
      {
        name: 'Hyper Local Map View',
        description: 'Enable fast, short-distance deliveries',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_CREATE_TRIP]}?map_view=true&tab=hyperlocal_trip_enabled`,
        synonyms: ['hyper local', 'hyper local map view', 'create trip']
      },
      {
        name: 'Services Table View',
        description: 'Configure custom delivery options',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_CREATE_TRIP]}?map_view=false&tab=service_trip_enabled`,
        synonyms: ['services', 'services table view', 'create trip']
      },
      {
        name: 'Services Map View',
        description: 'Configure custom delivery options',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_CREATE_TRIP]}?map_view=true&tab=service_trip_enabled`,
        synonyms: ['services', 'services map view', 'create trip']
      },
    ],
  },

  {
    title: 'Pending Trips',
    description: 'View unassigned or unstarted trips.',
    path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_PENDING_TRIPS]}?tab=trips_pending_assigned`,
    category: 'Pending Trips',
    synonyms: ['unassigned trips', 'requested trips', 'waiting trips', 'queued trips', 'request trips', 'schedule trips', 'suggested trips', 'rejected trips'],
    actions: [
      {
        name: 'Requested',
        description: 'Trips awaiting approval',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_PENDING_TRIPS]}?tab=trips_pending_assigned`,
        synonyms: ['requested trips', 'awaiting approval', 'pending trips', 'queued trips', 'request trips', 'schedule trips', 'suggested trips', 'rejected trips']
      },
      {
        name: 'Scheduled',
        description: 'Trips planned for a future Date & time',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_PENDING_TRIPS]}?tab=trips_pending_scheduled`,
        synonyms: ['scheduled trips', 'future trips', 'planned trips', 'scheduled trips', 'future trips', 'planned trips', 'pending trips']
      },
      {   
        name: 'Suggested',
        description: 'System-recommended trips for assignment',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_PENDING_TRIPS]}?tab=trips_pending_suggested`,
        synonyms: ['suggested trips', 'recommended trips', 'suggested trips', 'recommended trips', 'suggested trips', 'recommended trips', 'pending trips']
      },
      {
        name: 'Rejected',
        description: 'Trips declined or cancelled',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_PENDING_TRIPS]}?tab=trips_pending_rejected`,
        synonyms: ['rejected trips', 'cancelled trips', 'rejected trips', 'cancelled trips', 'rejected trips', 'cancelled trips', 'pending trips']
      },
      {
        name: 'Unassigned',
        description: 'Trips without a driver assigned',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_PENDING_TRIPS]}?tab=trips_pending_unassigned`,
        synonyms: ['unassigned trips', 'unassigned trips', 'unassigned trips', 'unassigned trips', 'unassigned trips', 'unassigned trips', 'pending trips']
      },
    ],
  },

  {
    title: 'Live Trips',
    description: 'Track ongoing deliveries with live updates.',
    path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_LIVE_TRIPS]}?tab=ongoing`,
    category: 'Live Trips',
    synonyms: ['active trips', 'ongoing trips', 'current trips', 'in progress', 'live trips'],
    actions: [
      {
        name: 'On going',
        description: 'Trips currently in progress',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_LIVE_TRIPS]}?tab=ongoing`,
        synonyms: ['on going', 'ongoing', 'current', 'in progress', 'live trips']
      },
      {
        name: 'Accepted',
        description: 'Trips assigned and acknowledged by drivers',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_LIVE_TRIPS]}?tab=accepted`,
        synonyms: ['accepted', 'accepted trips', 'live trips']
      },
      {
        name: 'Completed',
        description: 'Trips that have been completed',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_LIVE_TRIPS]}?tab=completed`,
        synonyms: ['completed', 'completed trips', 'live trips']
      },
    ],
  },

  {
    title: 'Fleet Management',
    description: 'Manage fleets, drivers, and vehicles for smooth deliveries.',
    path: ROUTES_MAPPING[ROUTES_KEYS.TMS_FLEET_MANAGEMENT],
    category: 'Fleet Management',
    synonyms: ['fleet operations', 'transport management', 'fleet control', 'driver and vehicle management', 'fleet', 'fleets'],
    actions: [
      {
        name: 'Fleets',
        description: 'Create and oversee delivery fleets.',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_FLEET_MANAGEMENT],
        synonyms: ['fleets', 'fleet operations', 'transport management', 'fleet control', 'fleet management', 'fleets']
      },
      {
        name: 'Drivers',
        description: 'Add, track, and manage driver details.',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_DRIVERS],
        synonyms: ['drivers', 'driver details', 'driver management', 'driver tracking', 'fleet management', 'fleet', 'fleets']
      },
      {
        name: 'Vehicles',
        description: 'Maintain vehicle records and reminders.',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_VEHICLES],
        synonyms: ['vehicles', 'vehicle records', 'vehicle reminders', 'vehicle management', 'fleet management', 'fleet', 'fleets']
      },
    ],
  },

  {
    title: 'Reminders',
    description: 'Schedule and track maintenance tasks.',
    path: ROUTES_MAPPING[ROUTES_KEYS.TMS_REMINDERS],
    category: 'Reminders',
    synonyms: ['maintenance reminders', 'service reminders', 'vehicle reminders', 'driver reminders', 'fleet management', 'fleet', 'fleets'],
    actions: [
      {
        name: 'Overdue',
        description: 'Missed vehicle maintenance tasks requiring immediate attention',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_REMINDERS]}?f=%257B%2522tab%2522%253A%2522over_due%2522%257D`,
        synonyms: ['overdue', 'overdue reminders', 'overdue tasks', 'overdue maintenance', 'overdue service', 'overdue vehicle', 'overdue driver', 'reminders']
      },
      {
        name: 'Due Soon',
        description: 'Upcoming vehicle maintenance tasks that need scheduling',
        path: `${ROUTES_MAPPING[ROUTES_KEYS.TMS_REMINDERS]}?f=%257B%2522tab%2522%253A%2522due_soon%2522%257D`,
        synonyms: ['due soon', 'duesoon', 'due soon reminders', 'due soon tasks', 'due soon maintenance', 'due soon service', 'due soon vehicle', 'due soon driver', 'reminders']
      },
    ] 
  },

  {
    title: 'Tags Management',
    description: 'Categorize shipments and trips with custom tags.',
    path: ROUTES_MAPPING[ROUTES_KEYS.TMS_PACKAGE_TAGS],
    category: 'Tags Management',
    synonyms: ['tagging system', 'custom labels', 'category management'],
    actions: [
      {
        name: 'Package Tags',
        description: 'Assign tags for streamlined tracking.',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_PACKAGE_TAGS],
      },
      {
        name: 'Trip Tags',
        description: 'Organize and manage trip categories.',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_TRIP_TAGS],
      },
    ],
  },

  {
    title: 'Automation Rules',
    description: 'Automate workflows and reduce manual effort.',
    path: ROUTES_MAPPING[ROUTES_KEYS.TMS_INTER_HUB_RULES],
    category: 'Automation Rules',
    synonyms: ['workflow automation', 'process automation', 'task automation'],
    actions: [
      {
        name: 'Inter Hub Rules',
        description: 'Automate order movement between hubs.',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_INTER_HUB_RULES],
      },
      {
        name: 'Triggers',
        description: 'Set up event-based automated actions.',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_TRIGGERS],
      },
    ],
  },

  {
    title: 'Seller Profile',
    description: 'Manage business details, hubs, API keys, and webhooks.',
    path: ROUTES_MAPPING[ROUTES_KEYS.TMS_CLIENT_PROFILE],
    category: 'Seller Profile',
    synonyms: ['seller dashboard', 'business profile', 'vendor profile', 'merchant account'],
    actions: [
      {
        name: 'Profile',
        description: 'Securely manage API keys and settings.',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_CLIENT_PROFILE],
      },
      {
        name: 'Hubs',
        description: 'Configure and oversee seller hubs.',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_SELLER_HUB],
      },
      {
        name: 'Webhooks',
        description: 'Set up real-time data integrations.',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_CLIENT_WEBHOOK],
      },
    ],
  },

  {
    title: 'Configurations',
    description: 'Customize system settings for efficient operations.',
    path: ROUTES_MAPPING[ROUTES_KEYS.TMS_SORTING_HUB],
    category: 'Configurations',
    synonyms: ['control panel', 'operational settings', 'setup options'],
    actions: [
      {
        name: 'Sorting Hubs',
        description: 'Manage hub locations for optimized processing.',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_SORTING_HUB],
      },
      {
        name: 'Delivery Zones',
        description: 'Define and refine delivery areas.',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_DELIVERY_ZONE],
      },
      {
        name: 'App Configurations',
        description: 'Manage app configurations',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_APP_CONFIG],
      },
      {
        name: 'Ledger',
        description: 'View and manage ledger transactions',
        path: ROUTES_MAPPING[ROUTES_KEYS.TMS_LEDGER],
      },
    ],
  },
];
