
const DocxIcon = ({ width = 36, height = 36 }: { width?: number, height?: number }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 216 216" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16236_1798)">
        <g opacity="0.3">
          <path d="M147.67 49.25C142.203 49.2447 136.961 47.0704 133.095 43.2046C129.229 39.3387 127.055 34.097 127.05 28.6299V1.85854e-06H34.92C31.3853 -0.00131167 27.8849 0.693646 24.6189 2.04541C21.3529 3.39718 18.3851 5.37922 15.8853 7.87818C13.3854 10.3771 11.4023 13.3441 10.0493 16.6096C8.69634 19.8751 8 23.3752 8 26.9099V189.13C8 192.665 8.69634 196.165 10.0493 199.43C11.4023 202.696 13.3854 205.663 15.8853 208.162C18.3851 210.661 21.3529 212.643 24.6189 213.994C27.8849 215.346 31.3853 216.041 34.92 216.04H145.52C152.657 216.04 159.502 213.205 164.548 208.158C169.595 203.112 172.43 196.267 172.43 189.13V49.25H147.67Z" fill="#B1B1B1"/>
        </g>
        <path d="M172.43 49.25H147.67C142.203 49.2447 136.961 47.0704 133.095 43.2046C129.229 39.3387 127.055 34.097 127.05 28.6299V0L172.43 49.25Z" fill="#999999"/>
        <path d="M138.6 83.7302H35.3601C34.3682 83.7302 33.417 83.3362 32.7156 82.6348C32.0142 81.9334 31.6201 80.9821 31.6201 79.9902C31.6188 79.4982 31.7146 79.0108 31.902 78.5559C32.0894 78.101 32.3646 77.6875 32.712 77.3391C33.0595 76.9908 33.4721 76.7145 33.9265 76.5259C34.3809 76.3373 34.8681 76.2402 35.3601 76.2402H138.6C139.595 76.2402 140.548 76.6354 141.252 77.3386C141.955 78.0419 142.35 78.9957 142.35 79.9902C142.347 80.9831 141.951 81.9344 141.248 82.6355C140.545 83.3366 139.593 83.7302 138.6 83.7302V83.7302Z" fill="#F9F9F9"/>
        <path d="M138.6 106.1H35.3601C34.3682 106.1 33.417 105.706 32.7156 105.005C32.0142 104.303 31.6201 103.352 31.6201 102.36C31.6188 101.868 31.7146 101.381 31.902 100.926C32.0894 100.471 32.3646 100.058 32.712 99.7092C33.0595 99.3609 33.4721 99.0846 33.9265 98.896C34.3809 98.7074 34.8681 98.6103 35.3601 98.6104H138.6C139.595 98.6104 140.548 99.0052 141.252 99.7085C141.955 100.412 142.35 101.366 142.35 102.36C142.347 103.353 141.951 104.304 141.248 105.005C140.545 105.706 139.593 106.1 138.6 106.1V106.1Z" fill="#F9F9F9"/>
        <path d="M138.6 128.47H35.3601C34.8681 128.47 34.3809 128.373 33.9265 128.184C33.4721 127.996 33.0595 127.719 32.712 127.371C32.3646 127.023 32.0894 126.609 31.902 126.154C31.7146 125.699 31.6188 125.212 31.6201 124.72C31.6201 123.728 32.0142 122.777 32.7156 122.075C33.417 121.374 34.3682 120.98 35.3601 120.98H138.6C139.593 120.98 140.545 121.374 141.248 122.075C141.951 122.776 142.347 123.727 142.35 124.72C142.35 125.715 141.955 126.668 141.252 127.372C140.548 128.075 139.595 128.47 138.6 128.47Z" fill="#F9F9F9"/>
        <path d="M97.8601 150.84H35.3601C34.8681 150.84 34.3809 150.743 33.9265 150.554C33.4721 150.366 33.0595 150.089 32.712 149.741C32.3646 149.393 32.0894 148.979 31.902 148.524C31.7146 148.07 31.6188 147.582 31.6201 147.09C31.6201 146.098 32.0142 145.147 32.7156 144.445C33.417 143.744 34.3682 143.35 35.3601 143.35H97.8601C98.852 143.35 99.8033 143.744 100.505 144.445C101.206 145.147 101.6 146.098 101.6 147.09C101.601 147.582 101.506 148.07 101.318 148.524C101.131 148.979 100.856 149.393 100.508 149.741C100.161 150.089 99.7481 150.366 99.2937 150.554C98.8393 150.743 98.3521 150.84 97.8601 150.84Z" fill="#F9F9F9"/>
        <path d="M191.95 116H66.9096C58.0399 116 50.8496 123.19 50.8496 132.06V169.34C50.8496 178.21 58.0399 185.4 66.9096 185.4H191.95C200.819 185.4 208.01 178.21 208.01 169.34V132.06C208.01 123.19 200.819 116 191.95 116Z" fill="#5468FA"/>
        <path d="M69.1793 158.77V166.04H61.6494V158.77H69.1793Z" fill="white"/>
        <path d="M98.12 158.79C96.8651 161.062 94.9744 162.918 92.6799 164.13C90.0954 165.452 87.2221 166.108 84.3199 166.04H72.96V135.97H84.3199C87.2241 135.899 90.1013 136.541 92.7 137.84C94.9874 139.028 96.873 140.864 98.12 143.12C99.4129 145.52 100.064 148.214 100.01 150.94C100.061 153.675 99.4107 156.378 98.12 158.79V158.79ZM90.2999 157.28C91.1128 156.446 91.7427 155.451 92.149 154.359C92.5554 153.267 92.7295 152.103 92.66 150.94C92.7299 149.779 92.5561 148.615 92.1497 147.525C91.7432 146.435 91.1131 145.442 90.2999 144.61C88.478 143.012 86.0984 142.2 83.6799 142.35H80.24V159.53H83.6799C86.0964 159.678 88.4743 158.87 90.2999 157.28Z" fill="white"/>
        <path d="M126.15 137.481C128.438 138.781 130.329 140.678 131.62 142.971C132.973 145.392 133.663 148.128 133.62 150.901C133.67 153.682 132.98 156.425 131.62 158.851C130.331 161.154 128.436 163.06 126.14 164.361C123.802 165.696 121.152 166.386 118.46 166.361C115.768 166.386 113.117 165.696 110.78 164.361C108.484 163.06 106.588 161.154 105.3 158.851C103.939 156.425 103.249 153.682 103.3 150.901C103.248 148.127 103.938 145.389 105.3 142.971C106.595 140.678 108.489 138.78 110.78 137.481C113.117 136.146 115.768 135.456 118.46 135.481C121.155 135.458 123.808 136.148 126.15 137.481V137.481ZM112.75 144.581C111.274 146.346 110.524 148.604 110.65 150.901C110.524 153.189 111.275 155.438 112.75 157.191C113.468 157.972 114.349 158.586 115.33 158.99C116.312 159.395 117.369 159.579 118.43 159.531C119.486 159.575 120.54 159.388 121.517 158.984C122.495 158.58 123.372 157.968 124.09 157.191C125.572 155.441 126.33 153.191 126.21 150.901C126.33 148.614 125.579 146.367 124.11 144.611C123.391 143.829 122.511 143.215 121.529 142.811C120.548 142.406 119.49 142.222 118.43 142.271C117.372 142.222 116.317 142.404 115.336 142.803C114.355 143.201 113.473 143.808 112.75 144.581V144.581Z" fill="white"/>
        <path d="M160.94 138.69C163.476 140.754 165.201 143.648 165.81 146.86H158.11C157.591 145.529 156.681 144.386 155.5 143.58C154.256 142.77 152.795 142.355 151.31 142.39C150.33 142.359 149.355 142.554 148.462 142.96C147.569 143.365 146.781 143.971 146.16 144.731C144.768 146.512 144.059 148.732 144.16 150.991C144.058 153.24 144.768 155.451 146.16 157.22C146.786 157.972 147.576 158.57 148.468 158.971C149.361 159.371 150.333 159.562 151.31 159.53C152.795 159.57 154.257 159.155 155.5 158.34C156.674 157.55 157.584 156.424 158.11 155.11H165.81C165.191 158.314 163.468 161.198 160.94 163.261C158.22 165.337 154.86 166.398 151.44 166.261C148.746 166.317 146.086 165.647 143.74 164.32C141.545 163.044 139.764 161.163 138.61 158.9C137.367 156.42 136.746 153.674 136.8 150.9C136.747 148.127 137.368 145.381 138.61 142.9C139.764 140.638 141.545 138.757 143.74 137.481C146.086 136.154 148.746 135.484 151.44 135.54C154.877 135.449 158.238 136.564 160.94 138.69Z" fill="white"/>
        <path d="M188.14 166.041L181.799 156.681L176.359 166.041H168.02L177.64 150.561L167.729 135.971H176.359L182.53 145.031L187.839 135.971H196.14L186.65 151.111L196.77 166.041H188.14Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_16236_1798">
          <rect width="216" height="216" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default DocxIcon;