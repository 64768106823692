
const PdfIcon = ({ width = 36, height = 36 }: { width?: number, height?: number }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 216 216" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path d="M147.67 49.25C142.203 49.2447 136.961 47.0704 133.095 43.2046C129.229 39.3387 127.055 34.097 127.05 28.6299V0H34.91C27.773 0 20.9284 2.83523 15.8818 7.88184C10.8352 12.9284 8 19.7729 8 26.9099V189.13C8.0106 196.26 10.8504 203.094 15.8959 208.132C20.9413 213.17 27.7799 216 34.91 216H145.52C152.65 216 159.489 213.17 164.534 208.132C169.58 203.094 172.419 196.26 172.43 189.13V49.25H147.67Z" fill="#B1B1B1"/>
      </g>
      <path d="M172.43 49.25H147.67C142.203 49.2447 136.961 47.0704 133.095 43.2046C129.229 39.3387 127.055 34.097 127.05 28.6299V0L172.43 49.25Z" fill="#999999"/>
      <path d="M138.6 150.84H35.3604C34.3658 150.84 33.4119 150.445 32.7087 149.742C32.0054 149.038 31.6104 148.085 31.6104 147.09C31.613 146.097 32.0092 145.146 32.7122 144.445C33.4152 143.744 34.3675 143.35 35.3604 143.35H138.6C139.592 143.35 140.544 143.744 141.245 144.445C141.946 145.147 142.34 146.098 142.34 147.09C142.342 147.582 142.246 148.07 142.059 148.524C141.871 148.979 141.596 149.393 141.248 149.741C140.901 150.089 140.488 150.366 140.034 150.554C139.58 150.743 139.092 150.84 138.6 150.84Z" fill="#F9F9F9"/>
      <path d="M138.6 108.6H35.3604C34.3675 108.6 33.4152 108.206 32.7122 107.505C32.0092 106.804 31.613 105.853 31.6104 104.86C31.6104 103.866 32.0054 102.912 32.7087 102.208C33.4119 101.505 34.3658 101.11 35.3604 101.11H138.6C139.092 101.11 139.58 101.207 140.034 101.396C140.488 101.585 140.901 101.861 141.248 102.209C141.596 102.558 141.871 102.971 142.059 103.426C142.246 103.881 142.342 104.368 142.34 104.86C142.34 105.852 141.946 106.803 141.245 107.505C140.544 108.206 139.592 108.6 138.6 108.6Z" fill="#F9F9F9"/>
      <path d="M138.6 129.72H35.3604C34.3658 129.72 33.4119 129.325 32.7087 128.622C32.0054 127.918 31.6104 126.965 31.6104 125.97C31.613 124.977 32.0092 124.026 32.7122 123.325C33.4152 122.624 34.3675 122.23 35.3604 122.23H138.6C139.592 122.23 140.544 122.624 141.245 123.325C141.946 124.027 142.34 124.978 142.34 125.97C142.342 126.462 142.246 126.949 142.059 127.404C141.871 127.859 141.596 128.273 141.248 128.621C140.901 128.969 140.488 129.246 140.034 129.434C139.58 129.623 139.092 129.72 138.6 129.72Z" fill="#F9F9F9"/>
      <path d="M97.8804 87.4802H35.3604C34.3675 87.4802 33.4152 87.0866 32.7122 86.3855C32.0092 85.6844 31.613 84.7331 31.6104 83.7402C31.6104 82.7457 32.0054 81.7919 32.7087 81.0886C33.4119 80.3854 34.3658 79.9902 35.3604 79.9902H97.8804C98.8732 79.9929 99.8245 80.3891 100.526 81.092C101.227 81.795 101.62 82.7474 101.62 83.7402C101.618 84.7313 101.223 85.681 100.522 86.3818C99.8212 87.0827 98.8715 87.4776 97.8804 87.4802V87.4802Z" fill="#F9F9F9"/>
      <path d="M97.8804 66.3601H35.3604C34.3675 66.3601 33.4152 65.9662 32.7122 65.2651C32.0092 64.564 31.613 63.6129 31.6104 62.6201C31.6104 61.6256 32.0054 60.6715 32.7087 59.9683C33.4119 59.265 34.3658 58.8701 35.3604 58.8701H97.8804C98.8732 58.8728 99.8245 59.2689 100.526 59.9719C101.227 60.6749 101.62 61.6273 101.62 62.6201C101.618 63.6112 101.223 64.5609 100.522 65.2617C99.8212 65.9625 98.8715 66.3575 97.8804 66.3601Z" fill="#F9F9F9"/>
      <path d="M191.94 116H66.8998C58.0301 116 50.8398 123.19 50.8398 132.06V169.34C50.8398 178.21 58.0301 185.4 66.8998 185.4H191.94C200.81 185.4 208 178.21 208 169.34V132.06C208 123.19 200.81 116 191.94 116Z" fill="#C21808"/>
      <path d="M91.8599 158.77V166.04H84.3398V158.77H91.8599Z" fill="white"/>
      <path d="M102.919 155.491V166.041H95.6494V135.971H107.429C110.999 135.971 113.729 136.861 115.599 138.631C116.546 139.56 117.284 140.68 117.767 141.916C118.25 143.152 118.465 144.476 118.399 145.801C118.436 147.549 118.004 149.275 117.149 150.801C116.276 152.292 114.982 153.492 113.429 154.251C111.556 155.136 109.5 155.564 107.429 155.501L102.919 155.491ZM110.999 145.801C110.999 143.134 109.539 141.801 106.619 141.801H102.919V149.631H106.619C109.539 149.657 110.999 148.381 110.999 145.801Z" fill="white"/>
      <path d="M147.13 158.79C145.877 161.064 143.986 162.921 141.69 164.13C139.105 165.453 136.232 166.11 133.33 166.04H121.979V135.97H133.33C136.234 135.897 139.112 136.539 141.71 137.84C144 139.024 145.886 140.862 147.13 143.12C148.431 145.518 149.085 148.213 149.029 150.94C149.084 153.677 148.429 156.381 147.13 158.79V158.79ZM139.31 157.28C140.122 156.446 140.752 155.451 141.159 154.359C141.565 153.268 141.739 152.103 141.669 150.94C141.739 149.779 141.566 148.616 141.159 147.526C140.753 146.435 140.123 145.442 139.31 144.61C137.491 143.014 135.115 142.202 132.7 142.35H129.25V159.53H132.7C135.113 159.677 137.487 158.869 139.31 157.28V157.28Z" fill="white"/>
      <path d="M172.759 135.971V141.761H160.429V148.351H169.949V153.881H160.429V166.041H153.149V135.971H172.759Z" fill="white"/>
      <path d="M136.77 58.8701H119.09C116.008 58.8701 113.51 61.3684 113.51 64.4501V82.1301C113.51 85.2119 116.008 87.7101 119.09 87.7101H136.77C139.852 87.7101 142.35 85.2119 142.35 82.1301V64.4501C142.35 61.3684 139.852 58.8701 136.77 58.8701Z" fill="#F9F9F9"/>
    </svg>

  );
};

export default PdfIcon;