
const JpgIcon = ({ width = 36, height = 36 }: { width?: number, height?: number }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 216 216" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16236_1801)">
        <g opacity="0.3">
          <path d="M147.67 49.24C142.202 49.2373 136.959 47.0641 133.093 43.1976C129.226 39.3312 127.053 34.0879 127.05 28.62V0H34.9102C31.3754 -2.4406e-07 27.8754 0.696337 24.6099 2.04932C21.3443 3.4023 18.3771 5.38537 15.8782 7.88525C13.3792 10.3851 11.3974 13.3529 10.0457 16.6189C8.69389 19.8849 7.99869 23.3853 8 26.92V189.13C7.99869 192.665 8.69389 196.165 10.0457 199.431C11.3974 202.697 13.3792 205.665 15.8782 208.165C18.3771 210.665 21.3443 212.648 24.6099 214.001C27.8754 215.354 31.3754 216.05 34.9102 216.05H145.51C149.045 216.05 152.545 215.354 155.81 214.001C159.076 212.648 162.043 210.665 164.542 208.165C167.041 205.665 169.023 202.697 170.375 199.431C171.726 196.165 172.421 192.665 172.42 189.13V49.25L147.67 49.24Z" fill="#B1B1B1"/>
        </g>
        <path d="M172.42 49.24H147.67C142.202 49.2373 136.959 47.0641 133.092 43.1976C129.226 39.3312 127.052 34.0879 127.05 28.62V0L172.42 49.24Z" fill="#999999"/>
        <path d="M112.3 146.41H68.1304C63.8063 146.405 59.6608 144.685 56.6033 141.627C53.5457 138.57 51.8256 134.424 51.8203 130.1V85.9302C51.8256 81.6061 53.5457 77.4606 56.6033 74.4031C59.6608 71.3455 63.8063 69.6254 68.1304 69.6201H112.3C116.623 69.6254 120.768 71.3458 123.824 74.4037C126.88 77.4615 128.598 81.607 128.6 85.9302V130.1C128.598 134.423 126.88 138.569 123.824 141.627C120.768 144.684 116.623 146.405 112.3 146.41ZM68.1304 76.8301C65.7177 76.8327 63.4045 77.7924 61.6985 79.4984C59.9925 81.2044 59.0329 83.5175 59.0303 85.9302V130.1C59.0329 132.513 59.9925 134.826 61.6985 136.532C63.4045 138.238 65.7177 139.197 68.1304 139.2H112.3C114.712 139.197 117.024 138.238 118.729 136.531C120.433 134.825 121.39 132.512 121.39 130.1V85.9302C121.39 83.5184 120.433 81.2053 118.729 79.499C117.024 77.7927 114.712 76.8327 112.3 76.8301H68.1304Z" fill="#F9F9F9"/>
        <path d="M125 121.7V130.1C124.997 133.467 123.658 136.696 121.277 139.077C118.896 141.458 115.667 142.797 112.3 142.8H68.1296C64.7614 142.8 61.5311 141.462 59.1494 139.08C56.7677 136.698 55.4297 133.468 55.4297 130.1V116C62.4997 114.48 73.2696 114 86.3396 119.22L94.4397 111.5L99.9595 125.5C99.9595 125.5 101.439 120.35 106.219 121.08C110.999 121.81 118.74 124.4 122.05 122.19C122.941 121.675 123.989 121.501 125 121.7V121.7Z" fill="#F9F9F9"/>
        <path d="M109.53 94.9498C112.242 94.9498 114.44 92.7516 114.44 90.0399C114.44 87.3282 112.242 85.1299 109.53 85.1299C106.818 85.1299 104.62 87.3282 104.62 90.0399C104.62 92.7516 106.818 94.9498 109.53 94.9498Z" fill="#F9F9F9"/>
        <path d="M191.94 116H66.8999C58.0302 116 50.8398 123.19 50.8398 132.06V169.34C50.8398 178.21 58.0302 185.4 66.8999 185.4H191.94C200.81 185.4 208 178.21 208 169.34V132.06C208 123.19 200.81 116 191.94 116Z" fill="#222222"/>
        <path d="M92.2192 158.77V166.05H84.6895V158.77H92.2192Z" fill="white"/>
        <path d="M114.57 135.98V156.521C114.57 159.674 113.714 162.097 112 163.791C111.044 164.672 109.92 165.353 108.695 165.791C107.47 166.229 106.169 166.416 104.87 166.34C103.448 166.417 102.024 166.21 100.682 165.733C99.3399 165.256 98.1054 164.518 97.0503 163.561C95.1236 161.701 94.1571 159.034 94.1504 155.561H101.34C101.34 158.371 102.41 159.771 104.53 159.771C106.38 159.771 107.3 158.691 107.3 156.541V136L114.57 135.98Z" fill="white"/>
        <path d="M126.629 155.5V166.051H119.359V135.98H131.139C134.706 135.98 137.426 136.864 139.299 138.63C140.247 139.559 140.988 140.679 141.472 141.915C141.956 143.15 142.174 144.475 142.109 145.801C142.137 147.548 141.706 149.272 140.859 150.801C139.989 152.291 138.694 153.489 137.139 154.24C135.267 155.129 133.211 155.561 131.139 155.5H126.629ZM134.709 145.801C134.709 143.134 133.249 141.801 130.329 141.801H126.629V149.63H130.329C133.249 149.657 134.709 148.381 134.709 145.801V145.801Z" fill="white"/>
        <path d="M169.08 138.4C171.501 140.22 173.13 142.902 173.63 145.89H165.93C165.409 144.843 164.587 143.976 163.57 143.4C162.425 142.719 161.112 142.373 159.78 142.4C158.746 142.357 157.716 142.543 156.762 142.944C155.808 143.345 154.954 143.951 154.26 144.719C152.794 146.469 152.047 148.71 152.17 150.99C152.17 153.85 152.9 156.046 154.36 157.58C155.181 158.376 156.16 158.992 157.232 159.388C158.305 159.784 159.449 159.951 160.59 159.88C162.222 159.909 163.819 159.407 165.14 158.45C166.49 157.442 167.464 156.013 167.91 154.39H158.72V149.16H173.99V156.35C173.407 158.139 172.483 159.8 171.27 161.24C169.957 162.791 168.322 164.04 166.48 164.9C164.398 165.862 162.124 166.34 159.83 166.3C157.04 166.373 154.28 165.707 151.83 164.37C149.589 163.109 147.765 161.222 146.58 158.94C145.308 156.468 144.672 153.719 144.73 150.94C144.68 148.177 145.316 145.446 146.58 142.99C147.763 140.712 149.579 138.826 151.81 137.56C154.224 136.233 156.946 135.567 159.7 135.63C163.048 135.484 166.349 136.458 169.08 138.4V138.4Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_16236_1801">
          <rect width="216" height="216" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default JpgIcon;