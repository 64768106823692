/**
 * ROUTES_KEYS
 * 
 * This file contains a centralized collection of unique route keys used throughout the application.
 * Each key represents a specific route/page in the application and follows the format:
 * SECTION_SUBSECTION: '_section_subsection'
 * 
 * The keys are organized into logical sections:
 * - Home
 * - POS (Point of Sale)
 * - Orders & Shipping
 * - Omniship
 * - TMS (Transport Management System)
 * - Returns
 * - Analytics
 * - Hubs
 * - Sellers
 * - Catalog & Inventory
 * - Settings
 * 
 * These keys are used in conjunction with ROUTES_MAPPING to create the actual URL paths.
 * Using this key-based approach provides:
 * 1. Type safety when referencing routes
 * 2. Centralized route management
 * 3. Easy route updates by changing only the mapping
 */

export const ROUTES_KEYS = {

  // Home
  HOME: '_home',
  DASHBOARD: '_dashboard',

  // POS
  POS: '_pos',
  POS_REGISTERS: '_pos_registers',
  POS_CASH_MANAGEMENT: '_pos_cash_management',
  POS_ORDERS: '_pos_orders',
  POS_ORDERS_DETAILS: '_pos_orders_details',
    
  // Orders
  SALES: '_orders',
  LIVE_ORDERS: '_live_orders',
  PACKING: '_packing',
  PENDING_ACTIONS: '_pending_actions',
  STOCK_TRANSFER_REQUESTS: '_stock_transfer_requests',
  MANIFEST: '_manifests',
  CREATE_ORDER: '_create_order',
  CREATE_PICKING_WAVE: '_create_picking_wave',
  
  // Shipments
  SHIPMENTS: '_shipments',
  FORWARD_SHIPMENT: '_forward_shipment',
  RETURN_SHIPMENT: '_return_shipment',
  SALES_SHIPMENTS_TRACKING: '_shipments_tracking',
  
  // Tax & Salesmen
  TAX_INVOICES: '_tax_invoices',
  SALESMEN: '_salesmen',

  // Shipment Orders
  SHIPMENT_ORDERS: '_shipment_orders',
  ORDER_STATUS: '_order_status',
  CREATE_SHIPMENT_ORDER: '_create_shipment_order',
  PICKUP_LOCATIONS: '_pickup_locations',

  // Bulk Ship
  BULK_SHIP: '_bulk_ship',
  PROCESS_BULK_SHIP: '_process_bulk_ship',
  BULK_SHIP_CLUSTERS: '_bulk_ship_clusters',
  BULK_SHIP_VIEW_CLUSTER: '_bulk_ship_view_cluster',
  BULK_SHIP_PROCESS_CLUSTER: '_bulk_ship_process_cluster',

  // Omniship
  OMNISHIP: '_omniship',
  OMNISHIP_SHIPMENT_ORDERS: '_omniship_shipment_orders',
  OMNISHIP_CREATE_SHIPMENT_ORDER: '_omniship_create_shipment_order',
  OMNISHIP_ALL_SHIPMENTS: '_omniship_all_shipments',
  OMNISHIP_PICKUP_ADDRESS: '_omniship_pickup_address',
  OMNISHIP_SINGLE_MILE: '_omniship_single_mile',
  OMNISHIP_MULTI_MILE: '_omniship_multi_mile',
  OMNISHIP_AUTOMATION: '_omniship_automation',

  // TMS
  TMS: '_tms',
  TMS_SHIPMENTS: '_tms_shipments',
  TMS_ALL_SHIPMENTS: '_tms_all_shipments',
  TMS_CREATE_SHIPMENT: '_tms_create_shipment',
  
  // TMS Trips
  TMS_TRIPS: '_tms_trips',
  TMS_CREATE_TRIP: '_tms_create_trip',
  TMS_PLANNED_ROUTES: '_tms_planned_routes',
  TMS_PENDING_TRIPS: '_tms_pending_trips',
  TMS_LIVE_TRIPS: '_tms_live_trips',
  TMS_ALL_TRIPS: '_tms_all_trips',

  // TMS Fleet Management
  TMS_FLEET_MANAGEMENT: '_tms_fleet_management',
  TMS_FLEETS: '_tms_fleets',
  TMS_VEHICLES: '_tms_vehicles',
  TMS_DRIVERS: '_tms_drivers',

  // TMS Tags Management
  TMS_TAGS_MANAGEMENT: '_tms_tags_management',
  TMS_PACKAGE_TAGS: '_tms_package_tags',
  TMS_TRIP_TAGS: '_tms_trip_tags',

  // TMS Automation & Reports
  TMS_AUTOMATION_RULES: '_tms_automation_rules',
  TMS_INTER_HUB_RULES: '_tms_inter_hub_rules',
  TMS_TRIGGERS: '_tms_triggers',
  TMS_REPORTS: '_tms_reports',

  // TMS Client Profile
  TMS_CLIENT_PROFILE: '_tms_client_profile',
  TMS_SELLER_HUB: '_tms_seller_hub',
  TMS_CLIENT_WEBHOOK: '_tms_client_webhook',

  // TMS Configurations
  TMS_SORTING_HUB: '_tms_sorting_hub',
  TMS_DELIVERY_ZONE: '_tms_delivery_zone',
  TMS_REMINDERS: '_tms_reminders',
  TMS_APP_CONFIG: '_tms_app_config',
  TMS_LEDGER: '_tms_ledger',

  // Returns
  RETURNS: '_returns',
  RETURN_REQUESTS: '_return_requests',
  CREATE_RETURN_REQUEST: '_create_return_request',
  RETURN_ORDERS: '_return_orders',
  CREATE_RETURN_ORDER: '_create_return_order',
  RETURN_GATE_ENTRY: '_return_gate_entry',
  RETURN_ORDER_PROCESSING: '_return_order_processing',
  NEW_RETURN_ORDER_PROCESSING: '_new_return_order_processing',
  CREATE_RETURN_ORDER_PROCESSING: '_create_return_order_processing',

  // Analytics
  DASHBOARD_ANALYTICS: '_dashboard_analytics',
  ANALYTICS: '_analytics',
  ANALYTICS_HUB: '_analytics_hub',
  ANALYTICS_SELLER: '_analytics_seller',
  ANALYTICS_SHIPPING: '_analytics_shipping',
  ANALYTICS_FULFILLMENT: '_analytics_fulfillment',
  ANALYTICS_LAST_MILE: '_analytics_last_mile',
  ANALYTICS_POS: '_analytics_pos',

  // Hubs
  HUBS: '_hubs',
  HUBS_ID: '_hubs_id',
  HUB_SETUP: '_hub_setup',
  HUB_OVERVIEW: '_hub_overview',
  HUB_LOCATIONS: '_hub_locations',
  HUB_ZONES: '_hub_zones',
  HUB_DELIVERY_ZONE: '_hub_delivery_zone',
  HUB_ASSETS: '_hub_assets',
  HUB_BINS: '_hub_bins',
  HUB_CARTS: '_hub_carts',
  HUB_PALLETS: '_hub_pallets',
  HUB_BOXES: '_hub_boxes',

  // Sellers
  SELLERS: '_sellers',

  // Catalog
  CATALOG: '_catalog',
  CATALOG_SKUS: '_catalog_skus',
  CATALOG_KITS: '_catalog_kits',
  CATALOG_COMPARISION: '_catalog_comparison',
  CATALOG_BARCODE_CONFIG: '_catalog_barcode_config',
  SALES_CHANNEL_LISTING: '_sales_channel_listing',
  CATALOG_COMPARED: '_catalog_comparison_catalogs_compared',
  CATALOG_EMBEDDED_BARCODE: '_catalog_embedded_barcode',

  // InventoryF
  INVENTORY: '_inventory',
  TOTAL_INVENTORY: '_total_inventory',
  LOCATION_INVENTORY: '_location_inventory',
  SKU_LOCATIONS: '_sku_locations',
  INVENTORY_BATCHES: '_inventory_batches',

  // Purchases
  PURCHASES: '_purchases',
  PURCHASE_ORDERS: '_purchase_orders',
  STOCK_OWNERSHIP_TRANSFER: '_stock_ownership_transfer',
  ALL_SUPPLIERS: '_all_suppliers',

  // Inventory Operations
  INVENTORY_OPERATIONS: '_inventory_operations',
  GATE_ENTRY: '_gate_entry',
  GRN: '_grn',
  NEW_GRN: '_new_grn',
  PUTAWAY: '_putaway',
  PENDING_PUTAWAY: '_pending_putaway',
  CYCLE_COUNT: '_cycle_count',
  CYCLE_COUNT_DETAILS: '_cycle_count_details',
  AD_HOC_USAGE: '_ad_hoc_usage_inventory',
  AD_HOC_USAGE_BILLING: '_ad_hoc_usage_billing',
  AD_HOC_ACTIVITIES: '_ad_hoc_activities',
  STOCK_TRANSFER: '_stock_transfer',
  SINGLE_BILLS_MODULE: '_single_bills_module',
  VIEW_BILL: '_view_bill',
  EXPIRED_INVENTORY_TASK: '_expired_inventory_task',

  // Reports
  REPORTS: '_reports',
  REPORTS_CATEGORY: '_reports_category',

  // Settings
  SETTINGS: '_settings',
  
  // Apps & Integration
  SHIPPING_APPS: '_shipping_apps',
  CUSTOM_APPS: '_custom_apps',
  TAX_AUTHORITY: '_tax_authority',
  FOOD_DRUG_COMPLIANCE: '_food_drug_compliance',

  // User Profile
  USER_PROFILE: '_user_profile',
  CHANGE_PASSWORD: '_change_password',

  // Order Settings
  ORDER_SETTINGS: '_order_settings',
  OMS_SETTINGS: '_oms_settings',
  ORDER_TAGS: '_order_tags',
  SHIPPING_AUTOMATION: '_shipping_automation',

  // Catalog Settings
  CATALOG_SETTINGS: '_catalog_settings',
  DEFAULT_SKUS_SETTINGS: '_default_skus_settings',

  // Billing
  BILLING: '_billing',
  BILLING_PROFILE_MODULE: '_billing_profile_module',
  BILLING_PROFILE: '_billing_profile',
  BILLING_PROFILE_CREATE: '_billing_profile_create',
  VIEW_BILLING_PROFILE: '_view_billing_profile',
  CONTRACTS: '_contracts',
  EDIT_CONTRACT: '_edit_contract',
  SINGLE_CONTRACTS_MODULE: '_single_contracts_module',
  VIEW_CONTRACT: '_view_contract',
  BILLS: '_bills',

  // Automation Rules
  AUTOMATION_RULES: '_automation_rules',
  PACKAGING_AUTOMATION: '_packaging_automation',
  HUB_ROUTING_AUTOMATION: '_hub_routing_automation',
  ADD_ITEMS_AUTOMATION: '_add_items_automation',
  GIFTING_AUTOMATION: '_gifting_automation',

  // Other Settings
  ADHOC_ACTIVITIES: '_adhoc_activities',
  SKU_CONFIGURATIONS: '_sku_configurations',
  USER_SETTINGS: '_user_settings',
  ROLE_SETTINGS: '_role_settings',
  MANAGE_INVOICE: '_manage_invoice',
  COMPANY_SETTINGS: '_company_settings',
  CUSTOMERS: '_customers',

  // City Mapping
  CITY_MAPPING: '_city_mapping',
  SYSTEM_CITIES: '_system_cities',
  SHIPPING_PARTNER_CITIES: '_shipping_partner_cities',

  // POS Settings
  POS_SETTINGS: '_pos_settings',

  // New key
  INVENTORY_REPORTS: '_inventory_reports',

  // Added key
  SALES_CHANNEL: '_sales_channel',

  // Authentication
  TRACKING: '_tracking',
  SHIPMENTS_TRACKING: '_shipments_tracking',
  SHIPMENTS_UPDATE_LOCATION: '_shipments_update_location',
  LOGIN: '_login',
  FORGOT_PASSWORD: '_forgot_password',
  RESET_PASSWORD: '_reset_password',
  SET_PASSWORD: '_set_password',
  NOT_AUTHORIZED: '_not_authorized',
  TRACKING_DETAIL: '_tracking_detail',
  UPDATE_LOCATION: '_update_location',
  SHIPMENTS_TRACKING_ID: '_shipments_tracking_id',
  TRACKING_SHIPMENT: '_tracking_shipment',

};