const ClockIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Time 1">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 14.2329C11.4423 14.2329 14.2329 11.4423 14.2329 8C14.2329 4.55767 11.4423 1.76712 8 1.76712C4.55767 1.76712 1.76712 4.55767 1.76712 8C1.76712 11.4423 4.55767 14.2329 8 14.2329ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
          fill="#222222"
        />
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.0959 4.06836C8.30774 4.06836 8.47946 4.24008 8.47946 4.45192V7.86282C8.47946 8.00934 8.42356 8.15034 8.32316 8.25704L5.88212 10.8517C5.73696 11.006 5.49422 11.0134 5.33993 10.8683C5.18565 10.7231 5.17824 10.4804 5.32338 10.3261L7.71234 7.78678V4.45192C7.71234 4.24008 7.88406 4.06836 8.0959 4.06836Z"
          fill="#222222"
        />
      </g>
    </svg>
    
  );
};
export default ClockIcon;