
const XlsIcon = ({ width = 36, height = 36 }: { width?: number, height?: number }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 216 216" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16236_1800)">
        <g opacity="0.3">
          <path d="M147.67 49.25C142.203 49.2447 136.961 47.0704 133.095 43.2046C129.23 39.3387 127.055 34.097 127.05 28.6299V0H34.9102C27.7732 0 20.9284 2.83523 15.8818 7.88184C10.8352 12.9284 8 19.7729 8 26.9099V189.13C8 196.267 10.8352 203.112 15.8818 208.158C20.9284 213.205 27.7732 216.04 34.9102 216.04H145.51C152.647 216.04 159.492 213.205 164.538 208.158C169.585 203.112 172.42 196.267 172.42 189.13V49.25H147.67Z" fill="#B1B1B1"/>
        </g>
        <path d="M172.42 49.25H147.67C142.203 49.2447 136.961 47.0704 133.095 43.2046C129.229 39.3387 127.055 34.097 127.05 28.6299V0L172.42 49.25Z" fill="#999999"/>
        <path d="M126.89 150.84H53.5698C47.2198 150.84 42.0498 144.52 42.0498 136.74V83.55C42.0498 75.78 47.2198 69.46 53.5698 69.46H126.92C133.26 69.46 138.43 75.78 138.43 83.55V136.74C138.4 144.52 133.23 150.84 126.89 150.84ZM53.5698 76.6599C50.4598 76.6599 47.9399 79.75 47.9399 83.55V136.74C47.9399 140.54 50.4598 143.63 53.5698 143.63H126.92C130.02 143.63 132.54 140.54 132.54 136.74V83.55C132.54 79.75 130.02 76.6599 126.92 76.6599H53.5698Z" fill="#F9F9F9"/>
        <path d="M135.46 118.81H44.9697V126.02H135.46V118.81Z" fill="#F9F9F9"/>
        <path d="M135.46 94.0898H44.9697V101.3H135.46V94.0898Z" fill="#F9F9F9"/>
        <path d="M112.28 73.0601H105.07V147.23H112.28V73.0601Z" fill="#F9F9F9"/>
        <path d="M75.3506 73.0601H68.1406V147.23H75.3506V73.0601Z" fill="#F9F9F9"/>
        <path d="M191.94 116H66.8999C58.0302 116 50.8398 123.19 50.8398 132.06V169.34C50.8398 178.21 58.0302 185.4 66.8999 185.4H191.94C200.81 185.4 208 178.21 208 169.34V132.06C208 123.19 200.81 116 191.94 116Z" fill="#067603"/>
        <path d="M95.4304 158.77V166.04H87.9004V158.77H95.4304Z" fill="white"/>
        <path d="M117.46 166.041L111.13 156.681L105.68 166.041H97.3499L106.96 150.561L97.0498 135.971H105.68L111.85 145.031L117.17 135.971H125.46L115.98 151.111L126.1 166.041H117.46Z" fill="white"/>
        <path d="M149.3 161.961C148.437 163.342 147.198 164.448 145.73 165.151C143.942 165.988 141.983 166.395 140.01 166.341C137.033 166.465 134.099 165.596 131.67 163.871C130.595 163.064 129.716 162.026 129.098 160.833C128.48 159.641 128.139 158.324 128.1 156.981H135.84C135.895 157.994 136.326 158.949 137.05 159.661C137.77 160.332 138.726 160.692 139.71 160.661C140.11 160.684 140.512 160.628 140.891 160.496C141.271 160.364 141.62 160.158 141.92 159.891C142.186 159.632 142.396 159.321 142.536 158.976C142.675 158.632 142.741 158.262 142.73 157.891C142.739 157.538 142.678 157.187 142.549 156.859C142.42 156.531 142.227 156.232 141.98 155.981C141.45 155.445 140.822 155.017 140.13 154.721C139.403 154.401 138.383 154.011 137.07 153.551C135.476 153.025 133.919 152.394 132.41 151.661C131.169 151.022 130.105 150.088 129.31 148.941C128.387 147.552 127.932 145.906 128.01 144.241C127.973 142.591 128.449 140.97 129.37 139.601C130.316 138.263 131.623 137.222 133.14 136.601C134.887 135.89 136.764 135.549 138.65 135.601C141.513 135.443 144.342 136.288 146.65 137.991C147.632 138.79 148.436 139.785 149.012 140.913C149.587 142.041 149.92 143.277 149.99 144.541H142.12C142.032 143.656 141.652 142.826 141.04 142.181C140.727 141.881 140.356 141.649 139.95 141.497C139.545 141.346 139.112 141.279 138.68 141.301C137.93 141.267 137.193 141.509 136.61 141.981C136.34 142.236 136.131 142.548 135.996 142.893C135.861 143.239 135.804 143.611 135.83 143.981C135.814 144.653 136.066 145.304 136.53 145.791C137.027 146.31 137.626 146.722 138.29 147.001C139 147.311 140.02 147.711 141.35 148.191C142.972 148.719 144.553 149.368 146.08 150.131C147.326 150.803 148.401 151.755 149.22 152.911C150.153 154.321 150.615 155.991 150.54 157.681C150.526 159.194 150.097 160.675 149.3 161.961V161.961Z" fill="white"/>
        <path d="M161.83 160.471H171.57V166.041H154.57V135.971H161.85L161.83 160.471Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_16236_1800">
          <rect width="216" height="216" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
};

export default XlsIcon;