import { FC, SVGProps } from 'react';

interface XMLIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const XMLIcon: FC<XMLIconProps> = ({ size = 18, color = '#5468FA', ...props }) => {
  return (
    <svg 
      width={size} 
      height={size} 
      viewBox="0 0 18 18" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2917_29686)">
        <path 
          d="M15.9614 6.43852H15.5291V4.35303C15.5291 4.33993 15.5271 4.32692 15.5253 4.31365C15.5247 4.23084 15.4983 4.1495 15.4416 4.08516L11.9711 0.120621C11.9701 0.119608 11.9691 0.119248 11.9684 0.118235C11.9477 0.0951305 11.9236 0.0758169 11.8981 0.0592809C11.8905 0.0541175 11.8829 0.0499019 11.875 0.0454901C11.8528 0.0333799 11.8295 0.0235685 11.8054 0.0162091C11.7988 0.0144771 11.7929 0.0117974 11.7864 0.00999998C11.7599 0.00358439 11.7327 0.000229195 11.7054 0L3.17622 0C2.78674 0 2.47034 0.316764 2.47034 0.705914V6.43835H2.03822C1.48106 6.43835 1.0293 6.88992 1.0293 7.44718V12.6936C1.0293 13.2506 1.48106 13.7027 2.03822 13.7027H2.47037V17.2941C2.47037 17.6832 2.78678 18 3.17625 18H14.8233C15.2124 18 15.5292 17.6832 15.5292 17.2941V13.7027H15.9614C16.5184 13.7027 16.9702 13.2508 16.9702 12.6938V7.44737C16.9702 6.89012 16.5184 6.43852 15.9614 6.43852ZM3.17622 0.706044H11.3524V4.31748C11.3524 4.51248 11.5106 4.67042 11.7054 4.67042H14.8233V6.43865H3.17622V0.706044ZM12.0759 12.2395H11.0283L10.9578 10.4252C10.9368 9.85544 10.9154 9.16642 10.9154 8.47711H10.8947C10.7472 9.0822 10.55 9.75704 10.3674 10.3125L9.79072 12.1622H8.95353L8.44719 10.327C8.29278 9.77155 8.13115 9.09632 8.01834 8.4773H8.00428C7.97638 9.11734 7.955 9.84858 7.91977 10.4393L7.83559 12.2395H6.84399L7.14661 7.49982H8.57396L9.03824 9.0822C9.18585 9.63037 9.33369 10.221 9.43882 10.7764H9.4602C9.59356 10.2284 9.75556 9.60247 9.91033 9.07498L10.4166 7.49986H11.816L12.0759 12.2395ZM2.28707 12.2395L3.65815 9.84132L2.33645 7.49982H3.567L3.98164 8.36469C4.12269 8.65319 4.22782 8.88521 4.34079 9.15201H4.35458C4.46694 8.84998 4.55818 8.63913 4.67788 8.36469L5.079 7.49982H6.30233L4.96605 9.81341L6.37272 12.2395H5.13527L4.70605 11.3814C4.53037 11.0511 4.41792 10.805 4.28419 10.5305H4.27004C4.17171 10.805 4.05194 11.0511 3.90436 11.3814L3.51083 12.2395H2.28707ZM14.8233 17.103H3.17622V13.7027H14.8233V17.103H14.8233ZM15.86 12.2395H12.899V7.49982H13.9752V11.3396H15.8602V12.2395H15.86Z" 
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2917_29686">
          <rect width={size} height={size} fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default XMLIcon; 