
const CsvIcon = ({ width = 36, height = 36 }: { width?: number, height?: number }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 216 216" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path d="M147.67 49.25C142.203 49.2447 136.961 47.0704 133.095 43.2046C129.229 39.3387 127.055 34.097 127.05 28.6299V0H34.91C27.773 0 20.9284 2.83523 15.8818 7.88184C10.8352 12.9284 8 19.7729 8 26.9099V189.13C8.0106 196.26 10.8504 203.094 15.8959 208.132C20.9413 213.17 27.78 216 34.91 216H145.51C149.045 216.001 152.545 215.306 155.811 213.955C159.077 212.603 162.045 210.621 164.545 208.122C167.045 205.623 169.028 202.656 170.381 199.39C171.734 196.125 172.43 192.625 172.43 189.09V49.21L147.67 49.25Z" fill="#B1B1B1"/>
      </g>
      <path d="M172.43 49.25H147.67C142.203 49.2447 136.961 47.0704 133.095 43.2046C129.229 39.3387 127.055 34.097 127.05 28.6299V0L172.43 49.25Z" fill="#666666"/>
      <path d="M126.89 150.8H53.5403C47.1903 150.8 42.0303 144.48 42.0303 136.7V83.51C42.0303 75.74 47.1903 69.4199 53.5403 69.4199H126.89C133.24 69.4199 138.4 75.74 138.4 83.51V136.7C138.4 144.48 133.24 150.8 126.89 150.8ZM53.5403 76.6201C50.4403 76.6201 47.9103 79.71 47.9103 83.51V136.7C47.9103 140.5 50.4403 143.59 53.5403 143.59H126.89C129.99 143.59 132.51 140.5 132.51 136.7V83.51C132.51 79.71 129.99 76.6201 126.89 76.6201H53.5403Z" fill="#F9F9F9"/>
      <path d="M135.461 118.77H44.9707V125.98H135.461V118.77Z" fill="#F9F9F9"/>
      <path d="M135.461 94.0508H44.9707V101.261H135.461V94.0508Z" fill="#F9F9F9"/>
      <path d="M112.29 73.0195H105.08V147.19H112.29V73.0195Z" fill="#F9F9F9"/>
      <path d="M75.3506 73.0195H68.1406V147.19H75.3506V73.0195Z" fill="#F9F9F9"/>
      <path d="M191.94 116H66.8998C58.0302 116 50.8398 123.19 50.8398 132.06V169.34C50.8398 178.21 58.0302 185.4 66.8998 185.4H191.94C200.81 185.4 208 178.21 208 169.34V132.06C208 123.19 200.81 116 191.94 116Z" fill="#067603"/>
      <path d="M88.49 158.512V165.762H81V158.512H88.49Z" fill="white"/>
      <path d="M115.4 138.531C117.93 140.579 119.65 143.461 120.25 146.661H112.59C112.079 145.333 111.175 144.193 110 143.391C108.763 142.58 107.308 142.165 105.83 142.201C104.855 142.172 103.886 142.366 102.999 142.77C102.111 143.174 101.328 143.777 100.71 144.531C99.3355 146.308 98.6437 148.518 98.7597 150.761C98.6429 152.995 99.3352 155.196 100.71 156.961C101.33 157.711 102.114 158.31 103.002 158.71C103.889 159.11 104.857 159.302 105.83 159.271C107.308 159.307 108.763 158.892 110 158.081C111.175 157.296 112.085 156.173 112.61 154.861H120.27C119.657 158.051 117.94 160.922 115.42 162.971C112.716 165.05 109.367 166.112 105.96 165.971C103.276 166.03 100.626 165.363 98.2897 164.041C96.1007 162.775 94.3283 160.899 93.1897 158.641C91.9571 156.19 91.3394 153.475 91.3897 150.731C91.3382 147.984 91.9559 145.266 93.1897 142.811C94.3283 140.553 96.1007 138.677 98.2897 137.411C100.626 136.089 103.276 135.422 105.96 135.481C109.366 135.364 112.706 136.443 115.4 138.531V138.531Z" fill="white"/>
      <path d="M144.63 161.691C143.768 163.067 142.534 164.169 141.07 164.871C139.288 165.705 137.336 166.109 135.37 166.051C132.409 166.171 129.491 165.31 127.07 163.601C126.001 162.797 125.126 161.764 124.509 160.576C123.893 159.389 123.552 158.078 123.51 156.741H131.22C131.273 157.749 131.706 158.699 132.43 159.401C133.146 160.072 134.099 160.432 135.08 160.401C135.884 160.442 136.673 160.17 137.28 159.641C137.543 159.381 137.75 159.069 137.888 158.725C138.026 158.38 138.091 158.012 138.08 157.641C138.089 157.291 138.029 156.943 137.902 156.617C137.775 156.29 137.584 155.993 137.34 155.741C136.815 155.208 136.189 154.783 135.5 154.491C134.76 154.161 133.75 153.771 132.45 153.321C130.863 152.799 129.313 152.171 127.81 151.441C126.575 150.803 125.514 149.873 124.72 148.731C123.806 147.347 123.355 145.708 123.43 144.051C123.389 142.408 123.861 140.794 124.78 139.431C125.725 138.1 127.024 137.061 128.53 136.431C130.272 135.726 132.141 135.386 134.02 135.431C136.861 135.281 139.666 136.118 141.96 137.801C142.939 138.595 143.74 139.585 144.312 140.709C144.884 141.832 145.214 143.063 145.28 144.321H137.45C137.354 143.442 136.975 142.617 136.37 141.971C136.057 141.675 135.688 141.445 135.284 141.296C134.88 141.146 134.45 141.08 134.02 141.101C133.273 141.063 132.538 141.305 131.96 141.781C131.69 142.036 131.479 142.347 131.344 142.693C131.209 143.039 131.153 143.411 131.18 143.781C131.171 144.113 131.229 144.443 131.349 144.752C131.469 145.061 131.65 145.343 131.88 145.581C132.376 146.103 132.975 146.515 133.64 146.791C134.34 147.101 135.36 147.501 136.64 147.981C138.256 148.501 139.831 149.143 141.35 149.901C142.596 150.571 143.668 151.523 144.48 152.681C145.407 154.083 145.866 155.742 145.79 157.421C145.797 158.923 145.396 160.399 144.63 161.691V161.691Z" fill="white"/>
      <path d="M155.439 135.82L162.769 158.35L170.099 135.82H177.849L167.429 165.76H158.069L147.689 135.82H155.439Z" fill="white"/>
    </svg>
  );
};

export default CsvIcon;