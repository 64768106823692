
const PngIcon = ({ width = 36, height = 36 }: { width?: number, height?: number }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 216 216" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path d="M147.67 49.24C142.203 49.2347 136.961 47.0606 133.095 43.1947C129.229 39.3288 127.055 34.0871 127.05 28.62V0H34.91C27.773 0 20.9284 2.83517 15.8818 7.88177C10.8352 12.9284 8 19.773 8 26.91V189.13C8.0106 196.26 10.8504 203.094 15.8959 208.132C20.9413 213.17 27.7799 216 34.91 216H145.52C152.652 216 159.492 213.169 164.538 208.129C169.583 203.089 172.422 196.252 172.43 189.12V49.24H147.67Z" fill="#B1B1B1"/>
      </g>
      <path d="M172.43 49.24H147.67C142.203 49.2347 136.961 47.0606 133.095 43.1947C129.229 39.3288 127.055 34.0871 127.05 28.62V0L172.43 49.24Z" fill="#999999"/>
      <path d="M62.7002 66.7197H48.9502V80.4697H62.7002V66.7197Z" fill="#F9F9F9"/>
      <path d="M76.46 80.4697H62.71V94.2197H76.46V80.4697Z" fill="#F9F9F9"/>
      <path d="M90.21 66.7197H76.46V80.4697H90.21V66.7197Z" fill="#F9F9F9"/>
      <path d="M103.96 80.4697H90.21V94.2197H103.96V80.4697Z" fill="#F9F9F9"/>
      <path d="M117.73 66.7197H103.98V80.4697H117.73V66.7197Z" fill="#F9F9F9"/>
      <path d="M131.48 80.4697H117.73V94.2197H131.48V80.4697Z" fill="#F9F9F9"/>
      <path d="M62.7002 94.2002H48.9502V107.95H62.7002V94.2002Z" fill="#F9F9F9"/>
      <path d="M76.46 107.95H62.71V121.7H76.46V107.95Z" fill="#F9F9F9"/>
      <path d="M90.21 94.2002H76.46V107.95H90.21V94.2002Z" fill="#F9F9F9"/>
      <path d="M103.96 107.95H90.21V121.7H103.96V107.95Z" fill="#F9F9F9"/>
      <path d="M117.73 94.2002H103.98V107.95H117.73V94.2002Z" fill="#F9F9F9"/>
      <path d="M131.48 107.95H117.73V121.7H131.48V107.95Z" fill="#F9F9F9"/>
      <path d="M62.7002 121.81H48.9502V135.56H62.7002V121.81Z" fill="#F9F9F9"/>
      <path d="M76.46 135.56H62.71V149.31H76.46V135.56Z" fill="#F9F9F9"/>
      <path d="M90.21 121.81H76.46V135.56H90.21V121.81Z" fill="#F9F9F9"/>
      <path d="M103.96 135.56H90.21V149.31H103.96V135.56Z" fill="#F9F9F9"/>
      <path d="M117.73 121.81H103.98V135.56H117.73V121.81Z" fill="#F9F9F9"/>
      <path d="M131.48 135.56H117.73V149.31H131.48V135.56Z" fill="#F9F9F9"/>
      <path d="M191.94 116H66.8998C58.0301 116 50.8398 123.19 50.8398 132.06V169.34C50.8398 178.21 58.0301 185.4 66.8998 185.4H191.94C200.81 185.4 208 178.21 208 169.34V132.06C208 123.19 200.81 116 191.94 116Z" fill="#222222"/>
      <path d="M87.0593 158.77V166.04H79.5293V158.77H87.0593Z" fill="white"/>
      <path d="M98.1199 155.501V166.041H90.8398V135.971H102.62C106.2 135.971 108.923 136.857 110.79 138.631C111.737 139.56 112.478 140.679 112.962 141.915C113.446 143.151 113.664 144.475 113.6 145.801C113.631 147.55 113.196 149.276 112.34 150.801C111.471 152.293 110.176 153.492 108.62 154.241C106.749 155.133 104.692 155.565 102.62 155.501H98.1199ZM106.2 145.801C106.2 143.134 104.74 141.801 101.82 141.801H98.1199V149.631H101.82C104.74 149.657 106.2 148.381 106.2 145.801V145.801Z" fill="white"/>
      <path d="M143.88 166.041H136.61L124.43 147.581V166.041H117.16V135.971H124.43L136.6 154.601V135.971H143.87L143.88 166.041Z" fill="white"/>
      <path d="M172.29 138.4C174.716 140.216 176.347 142.9 176.84 145.89H169.14C168.635 144.819 167.824 143.921 166.81 143.31C165.664 142.63 164.352 142.284 163.02 142.31C161.985 142.267 160.954 142.452 159.998 142.851C159.043 143.25 158.187 143.854 157.49 144.62C156.029 146.376 155.286 148.619 155.41 150.9C155.41 153.76 156.14 155.957 157.6 157.49C158.421 158.287 159.399 158.903 160.472 159.299C161.545 159.695 162.689 159.862 163.83 159.79C165.461 159.818 167.058 159.316 168.38 158.36C169.727 157.352 170.698 155.923 171.14 154.3H161.96V149.07H177.23V156.4C176.639 158.188 175.712 159.848 174.5 161.29C173.191 162.842 171.56 164.091 169.72 164.95C167.634 165.913 165.357 166.392 163.06 166.35C160.269 166.421 157.509 165.751 155.06 164.41C152.819 163.154 150.994 161.27 149.81 158.99C148.542 156.535 147.907 153.802 147.96 151.04C147.9 148.261 148.536 145.511 149.81 143.04C150.993 140.766 152.809 138.883 155.04 137.62C157.453 136.291 160.176 135.625 162.93 135.69C166.265 135.526 169.558 136.48 172.29 138.4V138.4Z" fill="white"/>
    </svg>    
  );
};

export default PngIcon;