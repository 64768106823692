/**
 * Route Mapping System
 * 
 * This file implements a hierarchical routing structure that maps ROUTES_KEYS to actual URL paths.
 * The system is organized in three layers:
 * 
 * 1. PRIMARY_PREFIX:
 *    - Root level URL segments (e.g., 'dashboard', 'sales', 'pos', 'tms')
 *    - Defines the main sections of the application
 * 
 * 2. SECONDARY_PREFIX:
 *    - Combines root prefixes with additional path segments
 *    - Creates base paths for related features (e.g., '/pos/registers', '/sales/shipments')
 *    - Helps maintain consistent URL structure for related features
 * 
 * 3. ROUTES_MAPPING:
 *    - Maps each ROUTES_KEY to its complete URL path
 *    - Uses PRIMARY_PREFIX and SECONDARY_PREFIX to build full paths
 *    - Includes dynamic parameters where needed (e.g., :id, :hubId)
 * 
 * Benefits of this approach:
 * - Consistent URL structure
 * - Easy to maintain and update routes
 * - Prevents duplicate path definitions
 * - Makes route relationships clear
 * - Enables type-safe route navigation
 */

import { ROUTES_KEYS } from './route-keys';

// Route Prefixes

// Primary Routes
const PRIMARY_PREFIX = {
  DASHBOARD: 'dashboard',
  SALES: 'sales',           
  OMNISHIP: 'omniship',
  TMS: 'tms',
  RETURNS: 'order',
  HUBS: 'hubs',
  SELLERS: 'sellers',
  CATALOG: 'catalog',
  INVENTORY: 'inventory',
  SETTINGS: 'settings',
  POS: 'pos',
  ANALYTICS: 'analytics',  // currently it is placed here
};

// Secondary prefixes
const SECONDARY_PREFIX = {

  //order nested prefixes
  SHIPMENTS: `${PRIMARY_PREFIX.SALES}/shipments`,
  SHIPMENTS_ORDERS: `${PRIMARY_PREFIX.SALES}/shipments-orders`,
  BULK_SHIP: `${PRIMARY_PREFIX.SALES}/bulk-ship`,

  // POS prefixes
  POS_REGISTERS: `${PRIMARY_PREFIX.POS}/registers`,
  POS_ORDERS: `${PRIMARY_PREFIX.POS}/orders`,

  // TMS nested prefixes
  TMS_TRIPS: `${PRIMARY_PREFIX.TMS}/trips`,
  TMS_FLEET: `${PRIMARY_PREFIX.TMS}/fleet-management`,
  TMS_TAGS: `${PRIMARY_PREFIX.TMS}/tags-management`,
  TMS_AUTOMATION: `${PRIMARY_PREFIX.TMS}/automation-rules`,
  TMS_SETUP: `${PRIMARY_PREFIX.TMS}/setup`,
  TMS_SHIPMENTS: `${PRIMARY_PREFIX.TMS}/shipments`,
  TMS_CLIENT_PROFILE: `${PRIMARY_PREFIX.TMS}/client-profile`,
  
  //Hubs nested prefixes
  HUBS_ID: `${PRIMARY_PREFIX.HUBS}/:hubId`,

  // Inventory prefixes
  INVENTORY_REPORTS: `${PRIMARY_PREFIX.INVENTORY}/inventory-reports`,
  INVENTORY_OPERATIONS: `${PRIMARY_PREFIX.INVENTORY}/inventory-operations`,

  // Settings prefixes
  SETTINGS_ORDER: `${PRIMARY_PREFIX.SETTINGS}/order-settings`,
  SETTINGS_CATALOG: `${PRIMARY_PREFIX.SETTINGS}/catalog-settings`,
  SETTINGS_APP_INTEGRATION: `${PRIMARY_PREFIX.SETTINGS}/app-integration`,
  SETTINGS_BILLING: `${PRIMARY_PREFIX.SETTINGS}/billing`,
  SETTINGS_AUTOMATION_RULES: `${PRIMARY_PREFIX.SETTINGS}/automation-rules`,
  SETTINGS_USER_PROFILE: `${PRIMARY_PREFIX.SETTINGS}/user-profile`,
  SETTINGS_CITY_MAPPING: `${PRIMARY_PREFIX.SETTINGS}/city-mapping`,

};

export const ROUTES_MAPPING = {
  
  [ROUTES_KEYS.HOME]: '/',
  [ROUTES_KEYS.DASHBOARD]: `/${PRIMARY_PREFIX.DASHBOARD}/analytics`,
  
  // POS Routes
  [ROUTES_KEYS.POS]: `/${PRIMARY_PREFIX.POS}`,
  [ROUTES_KEYS.POS_REGISTERS]: `/${PRIMARY_PREFIX.POS}/registers`,
  [ROUTES_KEYS.POS_CASH_MANAGEMENT]: `/${PRIMARY_PREFIX.POS}/cash-management`,
  [ROUTES_KEYS.POS_ORDERS]: `/${PRIMARY_PREFIX.POS}/orders`,
  [ROUTES_KEYS.POS_ORDERS_DETAILS]: `/${PRIMARY_PREFIX.POS}/orders/:id/details`,

  // Sales Routes
  [ROUTES_KEYS.SALES]: `/${PRIMARY_PREFIX.SALES}`,
  [ROUTES_KEYS.LIVE_ORDERS]: `/${PRIMARY_PREFIX.SALES}/live-orders`,
  [ROUTES_KEYS.PACKING]: `/${PRIMARY_PREFIX.SALES}/live-orders/packing`,
  [ROUTES_KEYS.STOCK_TRANSFER_REQUESTS]: `/${PRIMARY_PREFIX.SALES}/stock-transfer-requests`,
  [ROUTES_KEYS.MANIFEST]: `/${PRIMARY_PREFIX.SALES}/manifest`,
  [ROUTES_KEYS.CREATE_ORDER]: `/${PRIMARY_PREFIX.SALES}/create/order`,
  [ROUTES_KEYS.CREATE_PICKING_WAVE]: `/${PRIMARY_PREFIX.SALES}/create/picking-wave`,
  [ROUTES_KEYS.TAX_INVOICES]: `/${PRIMARY_PREFIX.SALES}/tax-invoices`,
  [ROUTES_KEYS.SALESMEN]: `/${PRIMARY_PREFIX.SALES}/salesmen`,
  [ROUTES_KEYS.PENDING_ACTIONS]: `/${PRIMARY_PREFIX.SALES}/pending-actions`,
  [ROUTES_KEYS.BULK_SHIP]: `/${PRIMARY_PREFIX.SALES}/bulk-ship`,

  // orders Shipments Routes
  [ROUTES_KEYS.SHIPMENT_ORDERS]: `/${SECONDARY_PREFIX.SHIPMENTS_ORDERS}`,
  [ROUTES_KEYS.FORWARD_SHIPMENT]: `/${SECONDARY_PREFIX.SHIPMENTS}/forward-shipment`,
  [ROUTES_KEYS.RETURN_SHIPMENT]: `/${SECONDARY_PREFIX.SHIPMENTS}/return-shipment`,
  [ROUTES_KEYS.SHIPMENTS_TRACKING]: `/${SECONDARY_PREFIX.SHIPMENTS}/shipments-tracking`,

  // Shipment Orders Routes 
  [ROUTES_KEYS.ORDER_STATUS]: `/${SECONDARY_PREFIX.SHIPMENTS_ORDERS}/order-status`,
  [ROUTES_KEYS.CREATE_SHIPMENT_ORDER]: `/${SECONDARY_PREFIX.SHIPMENTS_ORDERS}/create-shipment-order`,
  [ROUTES_KEYS.PICKUP_LOCATIONS]: `/${SECONDARY_PREFIX.SHIPMENTS_ORDERS}/locations`,

  // Omniship Routes 
  [ROUTES_KEYS.OMNISHIP]: `/${PRIMARY_PREFIX.OMNISHIP}`,
  [ROUTES_KEYS.OMNISHIP_SHIPMENT_ORDERS]: `/${PRIMARY_PREFIX.OMNISHIP}/shipment-orders`,
  [ROUTES_KEYS.OMNISHIP_CREATE_SHIPMENT_ORDER]: `/${PRIMARY_PREFIX.OMNISHIP}/create-shipment-order`,
  [ROUTES_KEYS.OMNISHIP_ALL_SHIPMENTS]: `/${PRIMARY_PREFIX.OMNISHIP}/all-shipments`,
  [ROUTES_KEYS.OMNISHIP_PICKUP_ADDRESS]: `/${PRIMARY_PREFIX.OMNISHIP}/pickup-address`,
  [ROUTES_KEYS.OMNISHIP_SINGLE_MILE]: `/${PRIMARY_PREFIX.OMNISHIP}/shipment-orders/single-mile`,
  [ROUTES_KEYS.OMNISHIP_MULTI_MILE]: `/${PRIMARY_PREFIX.OMNISHIP}/shipment-orders/multi-mile`,

  // TMS shipment Routes
  [ROUTES_KEYS.TMS_ALL_SHIPMENTS]: `/${SECONDARY_PREFIX.TMS_SHIPMENTS}/all-shipments`,
  [ROUTES_KEYS.TMS_CREATE_SHIPMENT]: `/${SECONDARY_PREFIX.TMS_SHIPMENTS}/create-shipments`,
  
  // TMS Trips Routes
  [ROUTES_KEYS.TMS_CREATE_TRIP]: `/${SECONDARY_PREFIX.TMS_TRIPS}/create-trips`,
  [ROUTES_KEYS.TMS_PLANNED_ROUTES]: `/${SECONDARY_PREFIX.TMS_TRIPS}/planned-routes`,
  [ROUTES_KEYS.TMS_PENDING_TRIPS]: `/${SECONDARY_PREFIX.TMS_TRIPS}/pending-trips`,
  [ROUTES_KEYS.TMS_LIVE_TRIPS]: `/${SECONDARY_PREFIX.TMS_TRIPS}/live-trips`,
  [ROUTES_KEYS.TMS_ALL_TRIPS]: `/${SECONDARY_PREFIX.TMS_TRIPS}/all-trips`,

  // TMS Fleet Management Routes
  [ROUTES_KEYS.TMS_FLEET_MANAGEMENT]: `/${SECONDARY_PREFIX.TMS_FLEET}/fleets`,
  [ROUTES_KEYS.TMS_VEHICLES]: `/${SECONDARY_PREFIX.TMS_FLEET}/vehicles`,
  [ROUTES_KEYS.TMS_DRIVERS]: `/${SECONDARY_PREFIX.TMS_FLEET}/drivers`,

  // TMS Tags Management Routes 
  [ROUTES_KEYS.TMS_PACKAGE_TAGS]: `/${SECONDARY_PREFIX.TMS_TAGS}/package-tags`,
  [ROUTES_KEYS.TMS_TRIP_TAGS]: `/${SECONDARY_PREFIX.TMS_TAGS}/trip-tags`,

  // TMS Automation & Reports Routes
  [ROUTES_KEYS.TMS_INTER_HUB_RULES]: `/${SECONDARY_PREFIX.TMS_AUTOMATION}/inter-hub-rules`,
  [ROUTES_KEYS.TMS_TRIGGERS]: `/${SECONDARY_PREFIX.TMS_AUTOMATION}/triggers`,
  [ROUTES_KEYS.TMS_REPORTS]: `/${PRIMARY_PREFIX.TMS}/reports`,
  
  // TMS Seller Profile Routes
  [ROUTES_KEYS.TMS_CLIENT_PROFILE]: `/${SECONDARY_PREFIX.TMS_CLIENT_PROFILE}/profile`,
  [ROUTES_KEYS.TMS_SELLER_HUB]: `/${SECONDARY_PREFIX.TMS_SETUP}/seller-hubs`,
  [ROUTES_KEYS.TMS_CLIENT_WEBHOOK]: `/${SECONDARY_PREFIX.TMS_CLIENT_PROFILE}/webhook`,

  // TMS Configurations Routes
  [ROUTES_KEYS.TMS_SORTING_HUB]: `/${SECONDARY_PREFIX.TMS_SETUP}/sorting-hubs`,
  [ROUTES_KEYS.TMS_DELIVERY_ZONE]: `/${SECONDARY_PREFIX.TMS_SETUP}/delivery-zones`,
  [ROUTES_KEYS.TMS_REMINDERS]: `/${SECONDARY_PREFIX.TMS_SETUP}/reminders`,
  [ROUTES_KEYS.TMS_APP_CONFIG]: `/${SECONDARY_PREFIX.TMS_SETUP}/app-config`,
  [ROUTES_KEYS.TMS_LEDGER]: `/${SECONDARY_PREFIX.TMS_SETUP}/ledger`,

  // Returns Routes
  [ROUTES_KEYS.RETURN_REQUESTS]: `/${PRIMARY_PREFIX.RETURNS}/return/return-request`,
  [ROUTES_KEYS.CREATE_RETURN_REQUEST]: '/orders/return/create-return-request',
  [ROUTES_KEYS.RETURN_ORDERS]: `/${PRIMARY_PREFIX.RETURNS}/return/return-order`,
  [ROUTES_KEYS.CREATE_RETURN_ORDER]: `/${PRIMARY_PREFIX.RETURNS}/return/create-return-order`,
  [ROUTES_KEYS.RETURN_GATE_ENTRY]: `/${PRIMARY_PREFIX.RETURNS}/return/return-gate-entry`,
  [ROUTES_KEYS.RETURN_ORDER_PROCESSING]: `/${PRIMARY_PREFIX.RETURNS}/return/return-order-processing`,
  [ROUTES_KEYS.CREATE_RETURN_ORDER_PROCESSING]: `/${PRIMARY_PREFIX.RETURNS}/return/return-order-processing/create-return-order-processing`,

  // Hubs Routes
  [ROUTES_KEYS.HUBS]: `/${PRIMARY_PREFIX.HUBS}`,
  [ROUTES_KEYS.HUBS_ID]: `/${SECONDARY_PREFIX.HUBS_ID}`,
  [ROUTES_KEYS.HUB_SETUP]: `/${SECONDARY_PREFIX.HUBS_ID}/overview`,
  [ROUTES_KEYS.HUB_OVERVIEW]: `/${SECONDARY_PREFIX.HUBS_ID}/overview`,
  [ROUTES_KEYS.HUB_LOCATIONS]: `/${SECONDARY_PREFIX.HUBS_ID}/location-layout`,
  [ROUTES_KEYS.HUB_ZONES]: `/${SECONDARY_PREFIX.HUBS_ID}/zones`,
  [ROUTES_KEYS.HUB_DELIVERY_ZONE]: `/${SECONDARY_PREFIX.HUBS_ID}/delivery-zone`,
  [ROUTES_KEYS.HUB_BINS]: `/${SECONDARY_PREFIX.HUBS_ID}/bins`,
  [ROUTES_KEYS.HUB_CARTS]: `/${SECONDARY_PREFIX.HUBS_ID}/carts`,
  [ROUTES_KEYS.HUB_PALLETS]: `/${SECONDARY_PREFIX.HUBS_ID}/pallets`,
  [ROUTES_KEYS.HUB_BOXES]: `/${SECONDARY_PREFIX.HUBS_ID}/boxes`,

  // Sellers Routes
  [ROUTES_KEYS.SELLERS]: `/${PRIMARY_PREFIX.SELLERS}`,

  // Catalog Routes
  [ROUTES_KEYS.CATALOG]: `/${PRIMARY_PREFIX.CATALOG}`,
  [ROUTES_KEYS.CATALOG_COMPARISION]: `/${PRIMARY_PREFIX.CATALOG}/catalog-comparision`,
  [ROUTES_KEYS.SALES_CHANNEL_LISTING]: `/${PRIMARY_PREFIX.CATALOG}/sales-channel-listing`,
  [ROUTES_KEYS.CATALOG_SKUS]: `/${PRIMARY_PREFIX.CATALOG}/skus`,
  [ROUTES_KEYS.CATALOG_KITS]: `/${PRIMARY_PREFIX.CATALOG}/kits`,
  [ROUTES_KEYS.CATALOG_EMBEDDED_BARCODE]: `/${PRIMARY_PREFIX.CATALOG}/barcode-config`,
  [ROUTES_KEYS.CATALOG_COMPARED]: `/${PRIMARY_PREFIX.CATALOG}/catalog-comparision/catalogs-compared`,

  // Inventory Routes
  [ROUTES_KEYS.INVENTORY_REPORTS]: `/${SECONDARY_PREFIX.INVENTORY_REPORTS}`,
  [ROUTES_KEYS.TOTAL_INVENTORY]: `/${SECONDARY_PREFIX.INVENTORY_REPORTS}/total-inventory`,
  [ROUTES_KEYS.LOCATION_INVENTORY]: `/${SECONDARY_PREFIX.INVENTORY_REPORTS}/location-inventory`,
  [ROUTES_KEYS.SKU_LOCATIONS]: `/${SECONDARY_PREFIX.INVENTORY_REPORTS}/sku-locations`,
  [ROUTES_KEYS.INVENTORY_BATCHES]: `/${SECONDARY_PREFIX.INVENTORY_REPORTS}/batches`,
  
  // Purchases Routes
  [ROUTES_KEYS.PURCHASE_ORDERS]: '/purchases/purchase-order',
  [ROUTES_KEYS.STOCK_OWNERSHIP_TRANSFER]: '/purchases/stock-ownership-transfer',
  [ROUTES_KEYS.ALL_SUPPLIERS]: '/purchases/all-suppliers',
  
  // Inventory Operations Routes
  [ROUTES_KEYS.INVENTORY_OPERATIONS]: `/${SECONDARY_PREFIX.INVENTORY_OPERATIONS}`,
  [ROUTES_KEYS.GATE_ENTRY]: `/${SECONDARY_PREFIX.INVENTORY_OPERATIONS}/gate-entry`,
  [ROUTES_KEYS.GRN]: `/${SECONDARY_PREFIX.INVENTORY_OPERATIONS}/grn`,
  [ROUTES_KEYS.NEW_GRN]: `/${SECONDARY_PREFIX.INVENTORY_OPERATIONS}/grn/new-grn`,
  [ROUTES_KEYS.PUTAWAY]: `/${SECONDARY_PREFIX.INVENTORY_OPERATIONS}/putaway`,
  [ROUTES_KEYS.PENDING_PUTAWAY]: `/${SECONDARY_PREFIX.INVENTORY_OPERATIONS}/pending-putaway`,
  [ROUTES_KEYS.CYCLE_COUNT]: `/${SECONDARY_PREFIX.INVENTORY_OPERATIONS}/cycle-count`,
  [ROUTES_KEYS.CYCLE_COUNT_DETAILS]: `/${SECONDARY_PREFIX.INVENTORY_OPERATIONS}/cycle-count/cycle-count-details`,
  [ROUTES_KEYS.AD_HOC_USAGE]: `/${SECONDARY_PREFIX.INVENTORY_OPERATIONS}/ad-hoc-usage`,
  [ROUTES_KEYS.STOCK_TRANSFER]: `/${PRIMARY_PREFIX.INVENTORY}/stock-transfer`,
  [ROUTES_KEYS.EXPIRED_INVENTORY_TASK]: `/${SECONDARY_PREFIX.INVENTORY_OPERATIONS}/expired-inventory-task`,

  // Bulk Ship Routes
  [ROUTES_KEYS.BULK_SHIP_CLUSTERS]: `/${SECONDARY_PREFIX.BULK_SHIP}`,
  [ROUTES_KEYS.BULK_SHIP_VIEW_CLUSTER]: `/${SECONDARY_PREFIX.BULK_SHIP}/view-cluster`,
  [ROUTES_KEYS.BULK_SHIP_PROCESS_CLUSTER]: `/${SECONDARY_PREFIX.BULK_SHIP}/process-cluster`,

  // Analytics Routes
  [ROUTES_KEYS.DASHBOARD_ANALYTICS]: `/${PRIMARY_PREFIX.TMS}/dashboard/analytics`,
  
  //Analytics Hubs
  [ROUTES_KEYS.ANALYTICS]: `/${PRIMARY_PREFIX.ANALYTICS}`,
  [ROUTES_KEYS.ANALYTICS_HUB]: `/${PRIMARY_PREFIX.ANALYTICS}/hub`,
  [ROUTES_KEYS.ANALYTICS_SELLER]: `/${PRIMARY_PREFIX.ANALYTICS}/seller`,
  [ROUTES_KEYS.ANALYTICS_SHIPPING]: `/${PRIMARY_PREFIX.ANALYTICS}/shipping`,
  [ROUTES_KEYS.ANALYTICS_FULFILLMENT]: `/${PRIMARY_PREFIX.ANALYTICS}/fulfillment`,
  [ROUTES_KEYS.ANALYTICS_LAST_MILE]: `/${PRIMARY_PREFIX.ANALYTICS}/last_mile`,
  [ROUTES_KEYS.ANALYTICS_POS]: `/${PRIMARY_PREFIX.ANALYTICS}/pos`,

  // Reports Route
  [ROUTES_KEYS.REPORTS]: '/reports',
  [ROUTES_KEYS.REPORTS_CATEGORY]: '/reports/:category/:report',

  // Settings Routes app-integration
  [ROUTES_KEYS.SETTINGS]: `/${PRIMARY_PREFIX.SETTINGS}`,
  [ROUTES_KEYS.SALES_CHANNEL]: `/${PRIMARY_PREFIX.SETTINGS}/sales-channel`,
  [ROUTES_KEYS.SHIPPING_APPS]: `/${PRIMARY_PREFIX.SETTINGS}/shipping-partners`,
  [ROUTES_KEYS.CUSTOM_APPS]: `/${PRIMARY_PREFIX.SETTINGS}/custom-apps`,
  [ROUTES_KEYS.TAX_AUTHORITY]: `/${PRIMARY_PREFIX.SETTINGS}/tax-authority`,
  [ROUTES_KEYS.FOOD_DRUG_COMPLIANCE]: `/${SECONDARY_PREFIX.SETTINGS_APP_INTEGRATION}/food-drug-compliance`,

  // User Profile Routes
  [ROUTES_KEYS.USER_PROFILE]: `/${SECONDARY_PREFIX.SETTINGS_USER_PROFILE}/edit-profile`,
  [ROUTES_KEYS.CHANGE_PASSWORD]: `/${SECONDARY_PREFIX.SETTINGS_USER_PROFILE}/change-password`,

  // Order Settings Routes
  [ROUTES_KEYS.ORDER_SETTINGS]: `/${SECONDARY_PREFIX.SETTINGS_ORDER}`,
  [ROUTES_KEYS.OMS_SETTINGS]: `/${SECONDARY_PREFIX.SETTINGS_ORDER}/oms-settings`,
  [ROUTES_KEYS.ORDER_TAGS]: `/${SECONDARY_PREFIX.SETTINGS_ORDER}/order-tags`,
  [ROUTES_KEYS.SHIPPING_AUTOMATION]: `/${SECONDARY_PREFIX.SETTINGS_ORDER}/shipping-automation`,

  // Catalog Settings Routes
  [ROUTES_KEYS.CATALOG_SETTINGS]: `/${SECONDARY_PREFIX.SETTINGS_CATALOG}`,
  [ROUTES_KEYS.DEFAULT_SKUS_SETTINGS]: `/${SECONDARY_PREFIX.SETTINGS_ORDER}/default-skus-settings`,

  // Billing Routes
  [ROUTES_KEYS.BILLING]: `/${SECONDARY_PREFIX.SETTINGS_BILLING}`,
  [ROUTES_KEYS.BILLING_PROFILE]: `/${SECONDARY_PREFIX.SETTINGS_BILLING}/profile`,
  [ROUTES_KEYS.CONTRACTS]: `/${SECONDARY_PREFIX.SETTINGS_BILLING}/contracts`,
  [ROUTES_KEYS.BILLS]: `/${SECONDARY_PREFIX.SETTINGS_BILLING}/bills`,
  [ROUTES_KEYS.AD_HOC_USAGE_BILLING]: `/${SECONDARY_PREFIX.SETTINGS_BILLING}/ad-hoc-usage`,
  [ROUTES_KEYS.AD_HOC_ACTIVITIES]: `/${SECONDARY_PREFIX.SETTINGS_BILLING}/ad-hoc-activities`,

  // Automation Rules Routes
  [ROUTES_KEYS.AUTOMATION_RULES]: `/${SECONDARY_PREFIX.SETTINGS_AUTOMATION_RULES}`,
  [ROUTES_KEYS.PACKAGING_AUTOMATION]: `/${SECONDARY_PREFIX.SETTINGS_AUTOMATION_RULES}/packaging-automation`,
  [ROUTES_KEYS.HUB_ROUTING_AUTOMATION]: `/${SECONDARY_PREFIX.SETTINGS_AUTOMATION_RULES}/hub-routing-automation`,
  [ROUTES_KEYS.OMNISHIP_AUTOMATION]: `/${SECONDARY_PREFIX.SETTINGS_AUTOMATION_RULES}/omniship-automation`,
  [ROUTES_KEYS.GIFTING_AUTOMATION]: `/${SECONDARY_PREFIX.SETTINGS_AUTOMATION_RULES}/gifting-automation`,

  // Other Settings Routes
  [ROUTES_KEYS.ADHOC_ACTIVITIES]: `/${PRIMARY_PREFIX.SETTINGS}/ad-hoc-activities`,
  [ROUTES_KEYS.SKU_CONFIGURATIONS]: `/${PRIMARY_PREFIX.SETTINGS}/sku-configurations`,
  [ROUTES_KEYS.USER_SETTINGS]: `/${PRIMARY_PREFIX.SETTINGS}/user-settings`,
  [ROUTES_KEYS.ROLE_SETTINGS]: `/${PRIMARY_PREFIX.SETTINGS}/role-settings`,
  [ROUTES_KEYS.MANAGE_INVOICE]: `/${PRIMARY_PREFIX.SETTINGS}/manage-invoice`,
  [ROUTES_KEYS.COMPANY_SETTINGS]: `/${PRIMARY_PREFIX.SETTINGS}/company-settings`,
  [ROUTES_KEYS.CUSTOMERS]: `/${PRIMARY_PREFIX.SETTINGS}/customers`,
  [ROUTES_KEYS.POS_SETTINGS]: `/${PRIMARY_PREFIX.SETTINGS}/pos-settings`,

  // City Mapping Routes
  [ROUTES_KEYS.CITY_MAPPING]: `/${SECONDARY_PREFIX.SETTINGS_CITY_MAPPING}`,
  [ROUTES_KEYS.SYSTEM_CITIES]: `/${SECONDARY_PREFIX.SETTINGS_CITY_MAPPING}/system-cities`,
  [ROUTES_KEYS.SHIPPING_PARTNER_CITIES]: `/${SECONDARY_PREFIX.SETTINGS_CITY_MAPPING}/shipping-partner-cities`,

  //Authentication
  [ROUTES_KEYS.TRACKING]: '/tracking',
  [ROUTES_KEYS.TRACKING_SHIPMENT]: '/shipments/tracking',
  [ROUTES_KEYS.TRACKING_DETAIL]: '/tracking/:trackingId',
  [ROUTES_KEYS.SHIPMENTS_TRACKING_ID]: '/shipments/tracking/:trackingId',
  [ROUTES_KEYS.UPDATE_LOCATION]: '/shipment-orders/update_location',
  //REMAIN
  [ROUTES_KEYS.SHIPMENTS_UPDATE_LOCATION]: '/shipments/update_location',

  // [ROUTES_KEYS.SHIPMENTS_TRACKING]: '/shipments-tracking',
  [ROUTES_KEYS.LOGIN]: '/login',
  [ROUTES_KEYS.FORGOT_PASSWORD]: '/forgot-password',
  [ROUTES_KEYS.RESET_PASSWORD]: '/reset-password',
  [ROUTES_KEYS.SET_PASSWORD]: '/set-password',
  [ROUTES_KEYS.NOT_AUTHORIZED]: '/misc/not-authorized',
};
