
const AddHubIllustration = ({primaryColor='var(--bs-primary)', primaryLightColor='var(--bs-primary-light)', primaryLighterColor='var(--bs-primary-lighter)'}) => {
  return (
    <svg width="661" height="300" viewBox="0 0 661 300" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.99" d="M41.9319 242.486H601.213C609.778 165.928 592.605 117.183 582.948 102.381C537.601 19.1933 463.912 9.18577 409.118 12.3131C365.282 14.815 311.915 60.0573 290.711 82.3657C211.354 146.164 177.973 116.141 106.803 133.654C49.8676 147.665 39.8325 212.046 41.9319 242.486Z" fill={primaryLighterColor}/>
      <path d="M430.18 78.3643H228.567V129.741V242.486H518.663V129.741L430.18 78.3643Z" fill={primaryLightColor} />
      <path opacity="0.13" d="M430.18 78.3643H228.567V129.741V242.486H518.663V129.741L430.18 78.3643Z" fill="white"/>
      <path d="M314.195 129.741L228.567 78.3643L142.938 129.741V242.486H314.195V129.741Z" fill={primaryLighterColor}/>
      <path d="M314.195 129.741L228.567 78.3643L142.938 129.741V242.486H314.195V129.741Z" fill={primaryLighterColor}/>
      <path opacity="0.12" d="M314.195 129.741L228.567 78.3643L142.938 129.741V242.486H314.195V129.741Z" fill={primaryColor}/>
      <rect opacity="0.1" width="131.297" height="22.8343" transform="matrix(-1 0 0 1 294.216 136.877)" fill={primaryColor}/>
      <rect opacity="0.31" width="117.026" height="82.7743" transform="matrix(-1 0 0 1 287.08 159.711)" fill={primaryColor}/>
      <path opacity="0.17" fillRule="evenodd" clipRule="evenodd" d="M184.325 173.983H195.742V183.973H192.889V191.109H198.597V202.526H214.295V242.486H170.053V202.526H175.762V191.109H175.763V183.973H184.325V173.983ZM242.838 216.797H214.295V242.486H242.838V216.797Z" fill="#222222"/>
      <path d="M519.257 130.114L313.736 130.114" stroke="#9BA8D2" strokeWidth="0.443571"/>
      <rect opacity="0.1" x="313.736" y="130.114" width="205.521" height="112.371" fill={primaryColor}/>
      <path d="M385.423 42.0162H261.223C273.052 23.6817 288.823 27.9697 295.23 32.4055C303.51 24.717 311.988 20.3304 315.191 19.0982C331.16 13.7753 340.08 25.7519 342.545 32.4055C369.159 21.7598 382.22 34.377 385.423 42.0162Z" fill={primaryColor} fillOpacity="0.07"/>
      <path d="M566.548 62.7164H468.963C474.877 55.619 485.227 55.816 489.663 56.8016C492.028 52.0699 496.563 48.4232 498.534 47.1913C514.503 42.4599 523.423 51.6268 525.888 56.8016C535.351 50.2959 541.659 55.0769 543.631 58.2807C559.008 52.3664 565.316 58.7735 566.548 62.7164Z" fill={primaryColor} fillOpacity="0.07"/>
      <path d="M484.488 12.4457H406.123C416.178 1.20907 426.084 3.82093 429.78 6.53144L437.173 2.09531C447.819 -3.22755 455.409 2.83487 457.873 6.53144C472.659 0.0257282 481.777 7.76358 484.488 12.4457Z" fill={primaryColor} fillOpacity="0.07"/>
      <path d="M489.686 121.243C506.018 121.243 519.257 108.003 519.257 91.6715C519.257 75.3397 506.018 62.1001 489.686 62.1001C473.354 62.1001 460.114 75.3397 460.114 91.6715C460.114 108.003 473.354 121.243 489.686 121.243Z" fill={primaryColor}/>
      <path opacity="0.39" d="M489.686 121.243C506.018 121.243 519.257 108.003 519.257 91.6715C519.257 75.3397 506.018 62.1001 489.686 62.1001C473.354 62.1001 460.114 75.3397 460.114 91.6715C460.114 108.003 473.354 121.243 489.686 121.243Z" fill="white"/>
      <rect x="486.577" y="77.5974" width="6.98878" height="30.4965" fill="white"/>
      <rect x="505.003" y="89.0327" width="6.98878" height="30.4965" transform="rotate(90 505.003 89.0327)" fill="white"/>
      <path opacity="0.38" d="M19.5 242.486L640.5 242.486" stroke="url(#paint0_linear_131_624)" strokeWidth="2.95714"/>
      <path opacity="0.17" d="M87.7941 268.863L19.5 298.671M204.529 268.863L176.98 298.671M330 268.863V298.671M455.471 268.863L483.814 298.671M571.412 268.863L640.5 298.671" stroke="#849AE8" strokeWidth="1.47857" strokeLinecap="round"/>
      <path opacity="0.7" d="M175.869 267.621H115.914C115.617 267.621 115.324 267.692 115.058 267.826L63.8034 293.853C62.0302 294.753 62.6707 297.429 64.6594 297.429H145.655C146.108 297.429 146.546 297.267 146.889 296.971L177.103 270.944C178.431 269.8 177.622 267.621 175.869 267.621Z" fill="url(#paint1_linear_131_624)"/>
      <path opacity="0.7" d="M484.17 267.621H544.744C545.039 267.621 545.33 267.69 545.594 267.823L597.361 293.85C599.142 294.745 598.505 297.429 596.512 297.429H514.685C514.235 297.429 513.801 297.269 513.458 296.977L482.943 270.95C481.605 269.81 482.412 267.621 484.17 267.621Z" fill="url(#paint2_linear_131_624)"/>
      <path opacity="0.7" d="M306.831 267.621H226.113C225.528 267.621 224.977 267.892 224.618 268.354L204.439 294.38C203.476 295.623 204.361 297.429 205.933 297.429H304.649C305.632 297.429 306.451 296.676 306.533 295.697L308.715 269.67C308.807 268.567 307.937 267.621 306.831 267.621Z" fill="url(#paint3_linear_131_624)"/>
      <path opacity="0.7" d="M351.927 267.621H432.646C433.23 267.621 433.782 267.892 434.14 268.354L454.32 294.38C455.283 295.623 454.397 297.429 452.825 297.429H354.109C353.126 297.429 352.307 296.676 352.225 295.697L350.043 269.67C349.951 268.567 350.821 267.621 351.927 267.621Z" fill="url(#paint4_linear_131_624)"/>
      <defs>
        <linearGradient id="paint0_linear_131_624" x1="19.5" y1="242.986" x2="640.5" y2="242.986" gradientUnits="userSpaceOnUse">
          <stop stopColor={primaryColor} stopOpacity="0"/>
          <stop offset="0.465" stopColor={primaryColor}/>
          <stop offset="1" stopColor={primaryColor} stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear_131_624" x1="139.56" y1="267.621" x2="139.56" y2="297.429" gradientUnits="userSpaceOnUse">
          <stop stopColor={primaryColor} stopOpacity="0.14"/>
          <stop offset="1" stopColor="#D9D9D9" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint2_linear_131_624" x1="520.853" y1="267.621" x2="520.853" y2="297.429" gradientUnits="userSpaceOnUse">
          <stop stopColor={primaryColor} stopOpacity="0.14"/>
          <stop offset="1" stopColor="#D9D9D9" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint3_linear_131_624" x1="273.283" y1="267.621" x2="273.283" y2="297.429" gradientUnits="userSpaceOnUse">
          <stop stopColor={primaryColor} stopOpacity="0.14"/>
          <stop offset="1" stopColor="#D9D9D9" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint4_linear_131_624" x1="385.476" y1="267.621" x2="385.476" y2="297.429" gradientUnits="userSpaceOnUse">
          <stop stopColor={primaryColor} stopOpacity="0.14"/>
          <stop offset="1" stopColor="#D9D9D9" stopOpacity="0"/>
        </linearGradient>
      </defs>
    </svg>

  )
}

export default AddHubIllustration;