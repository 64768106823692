const HolidayIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="holiday">
        <path
          id="Vector"
          d="M13.2333 13.8662L8.85 9.48288L9.61667 8.71621L14 13.0995L13.2333 13.8662ZM3.73333 13.0995C3.14444 12.4551 2.70833 11.7412 2.425 10.9579C2.14167 10.1745 2 9.37732 2 8.56621C2 7.69954 2.16111 6.8551 2.48333 6.03288C2.80556 5.21066 3.29444 4.47177 3.95 3.81621C4.60556 3.16066 5.34722 2.67177 6.175 2.34954C7.00278 2.02732 7.85 1.86621 8.71667 1.86621C9.52778 1.86621 10.325 2.00788 11.1083 2.29121C11.8917 2.57454 12.6 3.01066 13.2333 3.59954L3.73333 13.0995ZM3.78333 11.6162L4.93333 10.4495C4.75556 10.2051 4.57778 9.94121 4.4 9.65788C4.22222 9.37454 4.05833 9.07454 3.90833 8.75788C3.75833 8.44121 3.63333 8.11343 3.53333 7.77454C3.43333 7.43566 3.36667 7.08843 3.33333 6.73288C3.03333 7.5551 2.93056 8.39399 3.025 9.24954C3.11944 10.1051 3.37222 10.894 3.78333 11.6162ZM5.73333 9.69954L9.83333 5.56621C9.32222 5.16621 8.80278 4.85232 8.275 4.62454C7.74722 4.39677 7.24444 4.24677 6.76667 4.17454C6.28889 4.10232 5.86389 4.10232 5.49167 4.17454C5.11944 4.24677 4.83333 4.38288 4.63333 4.58288C4.43333 4.78288 4.30556 5.06899 4.25 5.44121C4.19444 5.81343 4.20833 6.2301 4.29167 6.69121C4.375 7.15232 4.53611 7.64121 4.775 8.15788C5.01389 8.67454 5.33333 9.18843 5.73333 9.69954ZM10.5833 4.79954L11.7833 3.64954C11.05 3.18288 10.2528 2.91066 9.39167 2.83288C8.53056 2.7551 7.68889 2.87732 6.86667 3.19954C7.2 3.23288 7.53056 3.29954 7.85833 3.39954C8.18611 3.49954 8.50833 3.61899 8.825 3.75788C9.14167 3.89677 9.44722 4.0551 9.74167 4.23288C10.0361 4.41066 10.3167 4.59954 10.5833 4.79954Z"
          fill="#F9720A"
        />
      </g>
    </svg>
  );
};
export default HolidayIcon;